export enum OffersAppAreaEnum {
    SANACORP = 'Sanacorp Angebotsbereich',
    MEA = 'mea Angebotsbereich'
}

export enum OffersOrderTypeEnum {
    SCHEDULED_ORDER = 'Terminauftrag',
    IMMEDIATE_ORDER = 'Sofortauftrag',
    EXTRAS = 'Sonstiges'
}

export enum SortableOffersFieldsEnum {
    productName = 'productName',
    dosageForm = 'dosageForm',
    pzn = 'pzn'
}
