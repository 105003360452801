import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ReturnsRequestComponent } from './returns-request/returns-request.component';
import { ReturnsViewComponent } from './returns-view/returns-view.component';
import { CoreModule } from '../../../../../core/core.module';
import { CalendarWidgetsModule } from '../../../../calendar/widgets/calendar.widgets.module';
import { LogisticsWidgetsModule } from '../../widgets/logistics.widgets.module';
import { ReturnsWidgetComponent } from './returns-widget/returns-widget.component';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        CalendarWidgetsModule,
        IonicModule,
        LogisticsWidgetsModule
    ],
    declarations: [
        ReturnsRequestComponent,
        ReturnsRequestComponent,
        ReturnsViewComponent,
        ReturnsWidgetComponent
    ],
    exports: [
        ReturnsRequestComponent,
        ReturnsRequestComponent,
        ReturnsViewComponent,
        ReturnsWidgetComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ReturnsWidgetsModule {
}
