import { NavigationRoutesEnum } from '../../navigation/navigation.routes';
import { OffersAppAreaEnum } from '../core.enums';
import { FullMeaRoutesEnum } from '../enums/mea-routes.enum';
import { NavigationEnum } from '../enums/navigation.enum';
import { DisplayLocation } from '../enums/newsLocation.enum';

/**
 * get the navigation label by NavigationEnum
 */
export const NavigationLabel = {
    [NavigationEnum.dashboard]: 'Startseite',
    [NavigationEnum.meaChat]: 'mea Chat', // TODO remove with feature flag
    [NavigationEnum.mea]: 'mea',
    [NavigationEnum.logistics]: 'Warenlogistik',
    [NavigationEnum.offers]: 'Angebote',
    [NavigationEnum.seminars]: 'Seminare',
    [NavigationEnum.calendar]: 'Kalender',
    [NavigationEnum.statistics]: 'Statistiken',
    [NavigationEnum.representatives]: 'Vertreter',
    [NavigationEnum.knowledge]: 'Pharma Wissen',
    [NavigationEnum.settings]: 'Einstellungen',
    [NavigationEnum.releaseNotes]: 'Was gibt\'s Neues?',
    [NavigationEnum.releaseHistory]: 'Versionshistorie',
    [NavigationEnum.information]: 'Hilfe',
    [NavigationEnum.news]: 'Neuigkeiten',
    [NavigationEnum.sanavendi]: 'SanaVendi',
    [NavigationEnum.communications]: 'Kommunikation',
};

/**
 * news display location to base route map
 */
export const NewsLocationRouteMap = {
  [DisplayLocation.mea]: FullMeaRoutesEnum.info,
  [DisplayLocation.news]: NavigationRoutesEnum.news,
  [DisplayLocation.dynamicPage]: FullMeaRoutesEnum.dynamicContent
}

/**
 * offers app area to base route map
 */
export const OffersLocationRouteMap = {
  [OffersAppAreaEnum.MEA]: FullMeaRoutesEnum.offers,
  [OffersAppAreaEnum.SANACORP]: NavigationRoutesEnum.offers
}
