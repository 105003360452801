import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreModule } from '../../../../core/core.module';
import { CalendarWidgetsModule } from '../../../calendar/widgets/calendar.widgets.module';

import {
    DocumentActionsComponent,
    LogisticsBasePage,
    LogisticsHeaderBaseComponent,
    LogisticsSidebarBaseComponent
} from './logistics.widgets';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        CalendarWidgetsModule,
        IonicModule
    ],
    declarations: [
        DocumentActionsComponent,
        LogisticsBasePage,
        LogisticsHeaderBaseComponent,
        LogisticsSidebarBaseComponent
    ],
    exports: [
        DocumentActionsComponent,
    ]
})
export class LogisticsWidgetsModule {
}
