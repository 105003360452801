import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, Subscription } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';

// Core Files
import { InputValidationService, ModalService } from '../../../../../../core/core.services';
import { matomoIdsEnum, MeamindItemType, ModalClassEnum } from '../../../../../../core/core.enums';
import { MeamindMutations } from '../../../../../../core/core.store';
import { MeamindSimilarQuestionsComponent } from '../meamind-similar-questions/meamind-similar-questions.component';
import { TOOLTIPS } from '../../../../../../core/core.config';
import { unsubscribe, unsubscribeAll } from '../../../../../../core/util/subscriptions.util';
import { MeamindItemInterface } from '../../../../../../core/interfaces/meamind.interface';

@Component({
    selector: 'app-meamind-question-modal',
    templateUrl: './meamind-question-modal.component.html',
    styleUrls: ['./meamind-question-modal.component.scss'],
    animations: [
        trigger('suggestionsVisible', [
            state('visible', style({
                flex: '1 1 32%',
                width: '*',
                padding: '*',
                opacity: 1
            })),
            state('hidden', style({
                flex: '0 0 0',
                padding: 0,
                width: 0,
                opacity: 0
            })),
            transition('visible=>hidden', animate('250ms')),
            transition('hidden=>visible', animate('250ms')),
        ])
    ]
})
export class MeamindQuestionModalComponent implements OnInit {

    /**
     * MeamindQuestionModalComponent constructor
     *
     * @param meamindMutations : MeamindMutations
     * @param formBuilder : FormBuilder
     * @param modalService : ModalService
     */
    constructor(
        private meamindMutations: MeamindMutations,
        public formBuilder: FormBuilder,
        private modalService: ModalService
    ) { }

    static modalClass = ModalClassEnum.large;
    @Input() question: MeamindItemInterface = null;
    @Input() showSimilarQuestions = true;
    @ViewChild(MeamindSimilarQuestionsComponent) meamindSimilarQuestions: any;
    @ViewChild('firstField') firstField: any

    matomoId = matomoIdsEnum.meamindAskQuestion;

    // Popover Controller, provided by ionic
    popover;

    validationFormGroup: FormGroup;
    questionTitle = '';
    questionContent = '';
    areSuggestionsVisible = false;
    tooltips = TOOLTIPS;
    titleDebounceSubscription: Subscription;
    contentDebounceSubscription: Subscription;

    ngOnInit() {
        this.setValidationFormGroup();
        this.questionTitle = this.question?.title || '';
    }

    ionViewDidEnter() {
        this.firstField.setFocus();
    }

    ionViewWillLeave() {
        if(this.meamindSimilarQuestions) {
            this.meamindSimilarQuestions.clear();
        }
        unsubscribeAll([
            this.titleDebounceSubscription,
            this.contentDebounceSubscription
        ]);
    }

    /**
     * Validate the form data
     */
    setValidationFormGroup() {
        const questionValues = {
            title: this.question?.title || '',
            content: this.question?.content || '',
            tags: this.question?.tags || []
        };
        this.validationFormGroup = this.formBuilder.group({
            title: [questionValues.title, [InputValidationService.noWhitespaceValidator, Validators.required]],
            content: [questionValues.content, [InputValidationService.noWhitespaceValidator, Validators.required, Validators.minLength(2)]],
            tags: [questionValues.tags, Validators.required]
         //   category: ['',], // TODO
        });

        if (this.showSimilarQuestions) {
            unsubscribe(this.titleDebounceSubscription);
            this.titleDebounceSubscription = this.validationFormGroup.controls['title']
                .valueChanges
                .pipe(debounceTime(750))
                .subscribe(value => {
                    this.questionTitle = value;
                    if(!this.areSuggestionsVisible && value.length > 4) {
                        this.areSuggestionsVisible = true;
                    }
                });

            unsubscribe(this.contentDebounceSubscription);
            this.contentDebounceSubscription = this.validationFormGroup.controls['content']
                .valueChanges
                .pipe(debounceTime(750))
                .subscribe(value => {
                    this.questionContent = value;
                    if(!this.areSuggestionsVisible && value?.length > 4) {
                        this.areSuggestionsVisible = true;
                    }
                });
        }
    }

    /**
     * User submitted the form
     *
     * @param values MeamindItemInterface
     */
    async onSubmitMeamindQuestion(values: MeamindItemInterface) {
        if (this.question?.id) {
            await this.meamindMutations.updateMeamindEntry(MeamindItemType.question, this.question.id, values.title, values.content);
        } else {
            await this.meamindMutations.createQuestion(
                values.title,
                values.content,
                values.tags
            );
        }
        await this.modalService.dismiss();
    }

    /**
     * User canceled the question
     */
    async onCancelClick() {
        await this.modalService.dismiss();
    }
}
