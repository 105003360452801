import { DateRangeOptionCodes } from '../enums/date-range.enum';
import { ReturnsFiltersInterface } from '../interfaces/returns.interface';
import { FilterResultInterface } from '../interfaces/filter-result.interface';
import { GetDateRangeConfig } from './date-range.config';
import { getReturnsStatusWithAll, getReturnsTypeWithAll, ReturnsStatusAllConfig, ReturnsTypeAllConfig } from './returns.config';
import { ReturnsTypeEnum } from '../enums/returns.enum';
import { UpdateTimeframeEnum } from '../enums/update-timeframe.enum';

export const defaultReturnsFilters: ReturnsFiltersInterface = {
    dateOption: DateRangeOptionCodes.all,
    dateFrom: null,
    dateTo: null,
    deliveryNoteDateOption: DateRangeOptionCodes.all,
    deliveryNoteDateFrom: null,
    deliveryNoteDateTo: null,
    deliveryNoteNumber: null,
    producer: null,
    pzn: null,
    status: ReturnsStatusAllConfig.shortcode,
    search: null,
    type: ReturnsTypeAllConfig.shortcode,
    updatesOnly: false,
    updatesUntil: UpdateTimeframeEnum.today
};

export const defaultReturnsWidgetFilters = {
    type: ReturnsTypeEnum.all,
};

export const ReturnsFilterResultConfig : FilterResultInterface = {
    hasDateFilter: true,
    hasSearchModal: true,
    defaultFilters: defaultReturnsFilters,
    items: [
        {
            id: 'date',
            label: 'Datum',
            isDate: true,
            isRecDate: true,
            dateRangeOption: GetDateRangeConfig()
        },
        {
            id: 'type',
            label: 'Typ',
            translation: getReturnsTypeWithAll()
        },
        {
            id: 'status',
            label: 'Status',
            translation: getReturnsStatusWithAll()
        },
        {
            id: 'producer',
            label: 'Hersteller',
            searchLabel: 'Hersteller',
            searchOnly: true
        },
        {
            id: 'pzn',
            label: 'PZN',
            searchLabel: 'PZN',
            searchOnly: true
        },
        {
            id: 'deliveryNoteDate',
            label: 'Lieferbeleg Datum',
            searchLabel: 'L. Datum',
            isDate: true,
            dateRangeOption: GetDateRangeConfig(),
            searchOnly: true
        },
        {
            id: 'deliveryNoteNumber',
            label: 'Lieferbeleg-#',
            searchLabel: 'L. Nr.',
            searchOnly: true
        },
        {
            id: 'search',
            label: 'Suche',
            searchOnly: true
        }
    ]};
