import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreModule } from '../../../../../core/core.module';
import { CalendarWidgetsModule } from '../../../../calendar/widgets/calendar.widgets.module';

import { DispositionRequestComponent } from './disposition-request/disposition-request.component';
import { OrderCopyComponent } from './order-copy/order-copy.component';
import { OrderViewComponent } from './order-view/order-view.component';
import { OrdersWidgetComponent } from './orders-widget/orders-widget.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        CalendarWidgetsModule,
        IonicModule
    ],
    declarations: [
        DispositionRequestComponent,
        OrderViewComponent,
        OrderCopyComponent,
        OrdersWidgetComponent
    ],
    exports: [
        DispositionRequestComponent,
        OrderViewComponent,
        OrderCopyComponent,
        OrdersWidgetComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class OrdersWidgetsModule {
}
