import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { gql } from '@apollo/client/core';

import { FetchPolicyKeys as FPK } from '../../../enums/fetch-policy-keys.enum';
import { QueryWrapper } from '../query.wrapper';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { DataChangedKeys as DCK } from '../../../enums/data-changed-keys.enum';
import { QueryFetchPolicy } from '../../../enums/api.enum';

export const GetCustomizableGrid = (queryName) => gql`
    query ${queryName}($type: customizableGridType_enum) {
        customizableGrid(where:{type: {_eq: $type } }, limit: 1) {
            type
            content
        }
    }
`;



export const AllCustomizableGridQueries = [
    GetCustomizableGrid('test'),
];
@Injectable()
export class CustomizableGridQueries extends QueryWrapper {
    fetchPolicies = {
        [FPK.getCustomizableGrid]: QueryFetchPolicy.NETWORK_ONLY
    };

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar
    ) {
        super(apollo, dataChangedVar, {
            [DCK.customizableGridChanged]: [
                FPK.getCustomizableGrid,
            ]
        });
    }

    public getCustomizableGrid(type: string): Observable<any[]> {
        const fetchPolicyKey = FPK.getCustomizableGrid;
        return this.apollo.watchQuery({
            query: GetCustomizableGrid(fetchPolicyKey),
            variables: {type},
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(
                map(d => d?.data && d.data['customizableGrid']),
                map(d => {
                    if(d[0] && d[0].content) {
                        return JSON.parse(d[0].content);
                    }
                    return [];
                }),
                map(d => {
                        if (d) this.updateFetchPolicy(fetchPolicyKey);
                        return d;
                    }
                ));
    }
}
