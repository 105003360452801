import { Component, EventEmitter, HostListener, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import * as moment from 'moment';
import * as d3S from 'd3-selection';
import * as d3Scale from 'd3-scale';
import 'd3-transition';
import { unsubscribe } from '../../util/subscriptions.util';
import { StatisticsSizeEnum } from '../../enums/statistics.enum';

@Component({
    selector: 'app-statistics-mini-bar-chart',
    templateUrl: './statistics-mini-bar-chart.component.html',
    styleUrls: ['./statistics-mini-bar-chart.component.scss'],
})
export class StatisticsMiniBarChartComponent {

    constructor() {
        this.generateUniqueId();
    }
    @Input() data;
    @Input() monthlyMaximum = 0;
    @Input() label;


    @Input() isLoading = false;
    @Input() size = StatisticsSizeEnum.DEFAULT;
    @Input() rerender = new EventEmitter();
    @Output() barClicked = new EventEmitter();
    @ViewChild('miniBarChart') miniBarChart;

    leftOffset = 15;
    bottomOffset = 15;
    uniqueID = '';

    // Chart Variables
    width: number;
    height: number;
    svg: any;
    chart: any;
    lines: any;
    labels: any;
    barColors: any;
    textColors: any;
    xScale: any;
    yScale: any;
    xDomain: any;
    yDomain: any;
    yMax = 0;
    isChartInitialized = false;
    tries = 0;

    months = [];

    currentMonth = moment().format('MM');


    highlightLastYear = false;
    highlightCurrentMonth = false;
    renderSubscription: Subscription;

    @HostListener('window:resize')
    onResize() {
        this.renderChart();
    }



    ngOnInit() {
        unsubscribe(this.renderSubscription);
        this.renderSubscription = this.rerender.subscribe(() => {
            this.renderChart();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.isLoading && this.data
            || changes.data && !this.isLoading) {
            if(!this.isChartInitialized) {
                this.initChart();
            } else {
                this.renderChart();
            }

        }
    }

    /**
     * Called if ion view did enter
     */
    ionViewDidEnter() {
        if(!this.isChartInitialized) {
            this.initChart();
        }
    }


    renderChart() {
        if(this.miniBarChart && this.miniBarChart.nativeElement
            && this.miniBarChart.nativeElement.offsetHeight) {
            if (this.isChartInitialized) {
                // Remove svg first to make sure we receive the right sizes
                d3S.select('#' + this.uniqueID + ' svg').remove();
            }

            this.height = this.miniBarChart.nativeElement.offsetHeight - this.bottomOffset;
            this.width = this.miniBarChart.nativeElement.offsetWidth;
            this.initSVG();
            this.drawChart();
        }
    }

    /**
     * Generate a unique id to prevent duplicate id's in html
     */
    generateUniqueId() {
        this.uniqueID = 'miniBarChart_' + Math.round(Math.random() * 10000000);
    }

    /**
     * Check if it is possible to create the chart, initialize it if it is possible
     */
    initChart() {
        const isChartInitialized = this.height  > 0 && this.height === this.miniBarChart.nativeElement.offsetHeight - this.bottomOffset;
        if(this.miniBarChart && this.miniBarChart.nativeElement
            && this.miniBarChart.nativeElement.offsetHeight && !isChartInitialized && this.data) {
            this.renderChart();
            this.isChartInitialized = true;
        }
        if (this.tries < 1000 && !isChartInitialized) {
            this.tries++;
            setTimeout(() => {
                this.initChart();
            }, 250);
        }
    }

    /**
     * Initialize the chart svg
     */
    initSVG() {
        d3S.select('#' + this.uniqueID + ' svg').remove();
        this.svg = d3S.select('#'+this.uniqueID)
            .append('svg')
            .attr('height', '100%')
            .attr('width', '100%')
            .attr('preserveAspectRatio', 'xMinYMax meet')
            .attr('viewBox', `0 0 ${this.width} ${this.height}`);



        this.chart = this.svg.append('g')
            .attr('class', 'bars')
            .attr('transform', `translate(${this.leftOffset},-${this.bottomOffset})`);
        this.lines = this.svg.append('g')
            .attr('class', 'lines')
            .attr('transform', 'translate(0,0)');
        this.labels = this.svg.append('g')
            .attr('class', 'labels')
            .attr('transform', 'translate(0,0)');


        this.yMax = this.monthlyMaximum;
        this.yMax = this.yMax > 0 ? this.yMax : 100;


        this.xDomain = this.data.map(item => item.month);
        this.yDomain = [0, this.yMax];
        const barWidth = Math.floor((this.width - this.leftOffset) / this.data.length) - 1;

        this.xScale = d3Scale.scaleBand()
            .padding(0.1)
            .domain(this.xDomain)
            .range([0, this.width - this.leftOffset + (barWidth / this.data.length)]);

        this.yScale = d3Scale.scaleLinear()
            .domain(this.yDomain)
            .range([this.height, 0]);

        this.barColors = d3Scale.scaleOrdinal()
            .range(['var(--ion-color-light)', 'var(--ion-color-primary-light)']);
        this.textColors = d3Scale.scaleOrdinal()
            .range(['var(--ion-color-medium)', 'var(--ion-color-primary)']);
    }


    /**
     * Draw the chart depending on the values
     */
    drawChart() {
        this.xScale.domain(this.data.map(item => item.month));
        this.yScale.domain([0, this.yMax]);

        const update = this.chart.selectAll('.bar')
            .data(this.data);
        update.remove().exit();

        this.drawLabels();

        // Background
        update.enter()
            .append('rect')
            .attr('class', 'bar-bg')
            .attr('x', item => this.xScale(item.month))
            .attr('y', 0)
            .attr('width', this.xScale.bandwidth())
            .attr('height', this.height)
            .style('fill', 'var(--ion-color-light)');

        update.enter()
            .append('rect')
            .attr('class', 'bar')
            .attr('x', item => this.xScale(item.month))
            .attr('y', item => this.yScale(this.monthlyMaximum < item.value ? this.monthlyMaximum : item.value))
            .attr('width', this.xScale.bandwidth())
            .attr('height', item => this.height - this.yScale(this.monthlyMaximum < item.value ? this.monthlyMaximum : item.value))
            .style('fill', 'var(--status-colors-green)')
            .style('opacity', item => item.value / this.monthlyMaximum );

    }


    /**
     * Draw month labels
     */
    drawLabels() {
        this.labels.selectAll('.labels')
            .remove().exit();

        // Draw provided label
        if(this.label) {
            this.labels.append('text')
                .attr('x', -6)
                .attr('y', (this.height - this.bottomOffset) * -1)
                .text(this.label)
                .style('font-size', () => this.size === StatisticsSizeEnum.SMALL ?  'var(--ion-font-size-small-text)' : 'var(--ion-font-size-text)')
                .style('font-weight', 'var(--ion-font-weight-subheadline)')
                .style('transform', 'rotate(180deg)')
                .style('writing-mode', 'vertical-rl')
                .style('fill', 'var(--ion-color-medium)');
        }

        // Label first month
        this.labels.append('text')
            .attr('x', this.leftOffset)
            .attr('y', this.height - 4)
            .text(moment(1, 'MM').format('MMM'))
            .style('font-size', () => this.size === StatisticsSizeEnum.SMALL ?  'var(--ion-font-size-small-text)' : 'var(--ion-font-size-text)')
            .style('font-weight', 'var(--ion-font-weight-subheadline)')
            .style('fill','var(--ion-color-medium)');

        // Label last month
        this.labels.append('text')
            .attr('x', this.width - 24)
            .attr('y', this.height - 4)
            .text(moment(12, 'MM').format('MMM'))
            .style('font-size', () => this.size === StatisticsSizeEnum.SMALL ?  'var(--ion-font-size-small-text)' : 'var(--ion-font-size-text)')
            .style('font-weight', () => 'var(--ion-font-weight-subheadline)')
            .style('fill','var(--ion-color-medium)');
    }
}
