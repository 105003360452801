<ng-container *ngIf="currentSlideIndex$ | async as slideIndex">
    <ng-container *ngIf="answerTracker$ | async as answerTracker">
        <app-modal *ngIf="survey$ | async as survey" title="Umfrage" i18n-title [subtitle]="survey.title" i18n-subtitle>
            <ng-container content>
                <swiper-container #surveySwiper
                                  [modules]="swiperModules"
                                  [slidesPerView]="1"
                                  (swiperslidechange)="onSlideChange()"
                                  [allowSlideNext]="answerTracker[(slideIndex | stringToNumber)] === true"
                                  [spaceBetween]="14">
                    <swiper-slide [ngClass]="{'preview-container': true, 'display-flex': isIntroductionShort}">
                        <ng-container *ngIf="isIntroductionShort; else longIntroduction">
                            <div class="preview-flex-full">
                                <h1 class="preview-heading" i18n>{{survey.title}}</h1>
                                <div [innerHTML]="survey.introduction | markdown | async" i18n></div>
                            </div>
                            <ion-img *ngIf="survey.logoUrl" [src]="survey.logoUrl" alt="Survey Logo" class="logo-short"/>
                        </ng-container>

                        <ng-template #longIntroduction>
                            <ion-img *ngIf="survey.logoUrl" [src]="survey.logoUrl" alt="Survey Logo" class="logo"/>
                            <h1 class="preview-heading" i18n>{{survey.title}}</h1>
                            <div [innerHTML]="survey.introduction | markdown | async" i18n></div>
                        </ng-template>
                    </swiper-slide>
                    <swiper-slide *ngFor="let question of survey.questions; index as index">
                        <ng-container [ngSwitch]="question.__typename">
                            <ion-text class="description display-block ion-padding-bottom-big" *ngIf="question?.description" i18n>{{question?.description}}</ion-text>
                            <ion-text class="question-counter" i18n>
                                Frage {{index + 1}} von {{survey.questions.length}} {{!question.isAnswerRequired ? '(optional)' : ''}}
                            </ion-text>
                            <ion-text class="question ion-padding-bottom" i18n>{{question.question}}</ion-text>
                            <ng-container *ngSwitchCase="questionTypes.freeText">
                                <ion-textarea
                                        class="text-area"
                                        [debounce]="300"
                                        (ionInput)="onFreeTextInput(question.id, $event.target.value, questionTypes.freeText, slideIndex)"
                                        fill="outline"
                                        aria-label="bitte tragen Sie Ihre Antwort hier ein"
                                ></ion-textarea>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <div class="display-flex-column width-fit-content" *ngIf="question.isMultipleChoice; else isSingleChoice">
                                    <ng-container *ngFor="let answer of question.answers">
                                        <ion-checkbox
                                                class="ion-margin-bottom"
                                                (ionChange)="onAnswerSelected($event.target.checked, survey.id, question, answer.id, slideIndex)"
                                                labelPlacement="end"
                                                justify="start"
                                                mode="md"
                                        >
                                            {{answer.answer}}
                                        </ion-checkbox>
                                        <ion-input
                                                *ngIf="question.hasOthersAnswer && answer.id === otherAnswerId && answer.isSelected"
                                                class="text-input"
                                                aria-label="Sonstige Antwortmöglichkeit bitte ausfüllen"
                                                fill="outline"
                                                type="text"
                                                [value]="answer?.freeTextAnswer"
                                                [debounce]="300"
                                                (ionInput)="onFreeTextInput(question.id, $event.target.value, questionTypes.multipleChoice, slideIndex, answer.id)"
                                        ></ion-input>
                                    </ng-container>
                                </div>
                                <ng-template #isSingleChoice>
                                    <ion-radio-group class="display-flex-column width-fit-content">
                                        <ng-container *ngFor="let answer of question.answers">
                                            <ion-radio
                                                    class="ion-margin-bottom"
                                                    (click)="onAnswerSelected(true, survey.id, question, answer.id, slideIndex)"
                                                    aria-label="Antwort option: {{answer.answer}}"
                                                    labelPlacement="end"
                                                    justify="start"
                                                    mode="md">
                                                {{answer.answer}}
                                            </ion-radio>
                                            <ion-input
                                                    *ngIf="question.hasOthersAnswer && answer.id === otherAnswerId && answer.isSelected"
                                                    class="text-input"
                                                    aria-label="Sonstige Antwortmöglichkeit bitte ausfüllen"
                                                    fill="outline"
                                                    type="text"
                                                    [value]="answer?.freeTextAnswer"
                                                    [debounce]="300"
                                                    (ionInput)="onFreeTextInput(question.id, $event.target.value, questionTypes.multipleChoice, slideIndex, answer.id)"
                                            ></ion-input>
                                        </ng-container>
                                    </ion-radio-group>
                                </ng-template>
                            </ng-container>
                        </ng-container>
                    </swiper-slide>
                </swiper-container>
            </ng-container>
            <ng-container footer >
                <ion-button class="button-tertiary" *ngIf="(slideIndex | stringToNumber) > 0" (click)="slidePrev()" i18n>zurück</ion-button>
                <ion-button
                        class="button-tertiary"
                        (click)="slideNext()"
                        *ngIf="(slideIndex | stringToNumber) !== survey?.questions.length; else submitButton"
                        [disabled]="answerTracker[(slideIndex | stringToNumber)] === false"
                        i18n
                >
                    Weiter
                </ion-button>
                <ng-template #submitButton>
                    <ion-button [disabled]="!answerTracker[slideIndex]" (click)="submitSurvey()" i18n>Abschicken</ion-button>
                </ng-template>
            </ng-container>
        </app-modal>
    </ng-container>
</ng-container>
