import { gql } from '@apollo/client/core';
import { Injectable, inject } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable, switchMap } from 'rxjs';

import { NewsPostInterface } from '../../../interfaces/news-post.interface';
import { QueryFetchPolicy } from '../../../enums/api.enum';
import { convertFromLocalTimeToUTC, lastQuarterHour } from '../../../formatting/date.formatting';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { formatStrapiNewsData, getFiltersStrapi } from '../utils';
import { PharmacyStoreStateVar } from '../../locals/pharmacyStoreState.var';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { QueryWrapper } from '../query.wrapper';
import {
    FetchPolicyKeys as FPK
} from '../../../enums/fetch-policy-keys.enum';
import{
    DataChangedKeys as DCK
} from '../../../enums/data-changed-keys.enum';
import { DisplayLocation } from '../../../enums/newsLocation.enum';
import { PharmacyStoreInterface } from '../../../core.interfaces';


export const newsPostFields = `data {
            id
            attributes {
                title
                subtitle
                displayLocation
                content
                contentHtml
                date
                location {
                    data {
                        id
                    }
                }
                image {
                    data {
                        attributes {
                            url
                            height
                            width
                        }
                    }
                }
                imageType
                form {
                    data {
                        id
                        attributes {
                            publishedAt
                        }
                    }
                }
                formButton
            }
        }`;

// We can't provide the date as a parameter (strapi returns error), so we have to inline it.
export const GetNewsPosts = (queryName) => gql`
   query ${queryName}($limit: Int, $filters: NewsPostFiltersInput) {
	newsPosts(
        sort: ["date:desc"],
        pagination: {limit: $limit}
        filters: $filters) {
        ${newsPostFields}
      }
    }
`;

export const GetNewsPost = (queryName) => gql`
   query ${queryName}($filters: NewsPostFiltersInput) {
	newsPosts(filters: $filters, pagination: {limit: 1}) {
        data {
            id
            attributes {
                title
                subtitle
                displayLocation
                content
                contentHtml
                date
                link
                videoLink
                publishedAt
                attachments {
                    data {
                        attributes {
                            url
                            mime
                            size
                            name
                        }
                    }
                }
                image {
                    data {
                        attributes {
                            url
                            height
                            width
                        }
                    }
                }
                imageType
                form {
                    data {
                        id
                        attributes {
                            publishedAt
                        }
                    }
                }
                formButton
            }
        }
      }
    }
`;

export const AllNewsPostQueries = [
    GetNewsPosts('test'),
    GetNewsPost('test')
];

export const getNewsPostVariables = (pharmacy: PharmacyStoreInterface, displayLocationValue: DisplayLocation, newsPostId = null, limit?: number) => {
    const additionalFilters = getFiltersStrapi(pharmacy);
    if (newsPostId) {
        return {
            filters: {
                id: {eq: newsPostId},
                date: {lte: convertFromLocalTimeToUTC(lastQuarterHour(), false, true)},
                ...additionalFilters
            }
        };
    }

    return {
        limit: limit ? limit : GraphQLLimits.newsPosts,
        filters: {
            date: {lte: convertFromLocalTimeToUTC(lastQuarterHour(), false, true)},
            publishedAt: {notNull: true},
            ...(displayLocationValue ? {displayLocation: {containsi: displayLocationValue}} : {}),
            ...additionalFilters
        }
    };
};

@Injectable()
export class NewsPostQueries extends QueryWrapper {
    private pharmacyStoreStateVar = inject(PharmacyStoreStateVar);

    fetchPolicies = {
        [FPK.getNewsPosts]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getNewsPost]: QueryFetchPolicy.NETWORK_ONLY
    };

    activePharmacyStore$ = this.pharmacyStoreStateVar.activePharmacyStoreState$;

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar) {
        super(apollo, dataChangedVar, {
            [DCK.newsPostChanged]:[FPK.getNewsPosts, FPK.getNewsPost]
        });
    }


    public getNewsPosts(displayLocationValue: DisplayLocation, limit?: number): Observable<NewsPostInterface[]> {
        const fetchPolicyKey = FPK.getNewsPosts;
        return this.activePharmacyStore$.pipe(
            switchMap((pharmacy) =>
                this.apollo
                    .watchQuery({
                        query: GetNewsPosts(fetchPolicyKey),
                        variables: getNewsPostVariables(pharmacy, displayLocationValue, null, limit),
                        fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
                    })
                    .valueChanges.pipe(map((d) => d?.data && d?.data['newsPosts']))
                    .pipe(map((newsData) => formatStrapiNewsData(newsData)))
                    .pipe(
                        map((d) => {
                            if (d) this.updateFetchPolicy(fetchPolicyKey);
                            return d;
                        })
                    )
            )
        );
    }

    public getNewsPost(newsPostId: string): Observable<NewsPostInterface> {
        const fetchPolicyKey = FPK.getNewsPost;
        return this.activePharmacyStore$.pipe(
            switchMap(
                (pharmacy) =>
                    this.apollo
                        .watchQuery({
                            query: GetNewsPost(fetchPolicyKey),
                            variables: { ...getNewsPostVariables(pharmacy, null, newsPostId) },
                            fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
                        })
                        .valueChanges.pipe(
                            map(
                                (d) =>
                                    d?.data &&
                                    d?.data['newsPosts'] &&
                                    d?.data['newsPosts']['data']?.length > 0 &&
                                    d?.data['newsPosts']['data'][0]
                            )
                        )
                        .pipe(map((newsData) => formatStrapiNewsData(newsData, false)))
                        .pipe(map((newsData) => (newsData.publishedAt ? newsData : null)))
                        .pipe(
                            map((d) => {
                                if (d) this.updateFetchPolicy(fetchPolicyKey);
                                return d;
                            })
                        ) as Observable<NewsPostInterface>
            )
        );
    }
}
