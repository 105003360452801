import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MarkdownModule } from 'ngx-markdown';

import { CoreModule } from '../../../core/core.module';

import { NewsSidebarComponent } from './news-sidebar/news-sidebar.component';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        IonicModule,
        MarkdownModule,
    ],
    declarations: [
        NewsSidebarComponent
    ],
    exports: [
        NewsSidebarComponent
    ]
})
export class NewsComponentsModule {
}
