import { Injectable } from '@angular/core';
import { NavigationRoutesEnum } from '../../navigation/navigation.routes';

interface LocationMappingInterface {
    locationKey?: string;
    routeMap: Record<string, string>;
    defaultRoute: string;
    forceOverwrite?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    setReturnUrl(url: string) {
        localStorage.setItem('navigation_return_url', url);
    }

    getReturnUrl(): string {
        return localStorage.getItem('navigation_return_url') || NavigationRoutesEnum.dashboard;
    }

    clearReturnUrl() {
        localStorage.removeItem('navigation_return_url');
    }

    /**
     * Sets the return URL based on the provided location configuration.
     *
     * This method checks if there is an existing return URL stored in the local storage.
     * If there is no existing URL or if the `forceOverwrite` flag in the configuration is set to true,
     * it sets the return URL to the value mapped from the `locationKey` in the `routeMap` or to the `defaultRoute`
     * if the `locationKey` is not found in the `routeMap`.
     *
     * @param {LocationMappingInterface} config - The configuration object containing location mapping details.
     * @param {string} config.locationKey - The key to look up in the route map.
     * @param {Record<string, string>} config.routeMap - The map of location keys to routes.
     * @param {string} config.defaultRoute - The default route to use if the location key is not found in the route map.
     * @param {boolean} config.forceOverwrite - Flag indicating whether to overwrite the existing return URL.
     *
     * @returns {string} - The return URL that was set or retrieved.
     */
    setReturnUrlFromLocation(config: LocationMappingInterface): string {
        const hasExistingUrl = localStorage.getItem('navigation_return_url');
        if (!hasExistingUrl || config.forceOverwrite) {
            const returnUrl =
                config.locationKey && config.routeMap[config.locationKey] ? config.routeMap[config.locationKey] : config.defaultRoute;
            this.setReturnUrl(returnUrl);
            return returnUrl;
        }
        return this.getReturnUrl();
    }
}
