import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { utils, writeFile } from 'xlsx';

import { StatisticDevelopmentType, StatisticTypeEnum } from '../../core.enums';
import { formatDateTimeToDate, getMonthFromDate, startOfCurrent, today } from '../../formatting/date.formatting';
import {
    CoopBonusInterface,
    MonthlyStatisticsInterface,
    StatisticsForecastCompareInterface
} from '../../interfaces/statistics.interface';

@Injectable({
    providedIn: 'root',
})
export class StatisticsService {

    constructor() { }

    /**
     * Get the pre-text, text, label and StatisticDevelopmentType of the returns widget
     * @param isReturnsDataAvailable boolean
     * @param returnsAllowanceRemaining number
     */
    static determineReturnsWidgetTextAndLabel(isReturnsDataAvailable: boolean, returnsAllowanceRemaining: number): {preText, text, label, badgeType} {
        const todayDate = today();
        const firstDayOfMonth = startOfCurrent('month', todayDate);
        // Text when no returns allowance is available
        let preText;
        let text = 'Freier Retouren-Anteil aufgebraucht. Für retournierte Ware fallen ggf. Gebühren an.';
        let label = 'Orientierungswert ohne Gewähr.';
        let returnsWidgetBadgeType = StatisticDevelopmentType.neutral;
        if (isReturnsDataAvailable) {
            if (returnsAllowanceRemaining > 0) {
                if (todayDate === firstDayOfMonth) {
                    preText = 'Stand Monatsabschluss.';
                    text = 'Retouren-Anteil gering;<br>keine Bearbeitungsgebühr!';
                    label = null;
                } else {
                    text = 'Retouren "auf dem Weg" sind<br>nicht berücksichtigt.';
                    label = 'Orientierungswert ohne Gewähr.';
                }
                returnsWidgetBadgeType = StatisticDevelopmentType.positive;
            } else if (todayDate === firstDayOfMonth) {
                preText = 'Stand Monatsabschluss.';
                text = 'Ihr freier Retouren-Anteil<br> ist leider aufgebraucht.';
                label = null;
            }
            return {
                preText,
                text,
                label,
                badgeType: returnsWidgetBadgeType
            };
        }
        return null;
    }

    /**
     * Returns an array of [year, month, value]
     *
     * @param data - subscription
     * @param keys - array of keys to filter data by
     * @param sort - sort array by value in descending order
     */
    transformDataByKeys(data, keys, sort=false) {
        const filtered = data.filter(item => keys.indexOf(item.name) !== -1);
        if (filtered) {
            let mapped = filtered.map(item => ({
                year: moment(item.yearMonth, 'YYYYMM').year(),
                month: getMonthFromDate(item.yearMonth),
                value: item.customValue
            }));
            if(sort) {
                mapped = mapped.sort(item => item.value > 0 ? -1 : 1);
            }
            return mapped;
        }
        return [];
    }

    transformToLatestSingleValue(data, key) {
        return [...data]
            .sort((a,b) => a.yearMonth < b.yearMonth ? 1 : a.yearMonth === b.yearMonth ? 0 : -1)
            .find(item => key === item.name);
    }

    /**
     * Get data from array by given key and month of year.
     * @param data array of data
     * @param key key to search for
     * @param previousMonth number - is subtracted from current month to get a previous yearMonth
     */
    transformToCurrentMonthValue(data, key, previousMonth = null) {
        const currentYearMonth = parseInt(moment().subtract(previousMonth, 'months').format('YYYYMM'));
        return [...data]
            .find(item => key === item.name && item.yearMonth === currentYearMonth);
    }

    /**
     * Returns separate arrays of {month, value} pairs for current and previous year
     *
     * @param data - getStatistics() subscription
     * @param keys - Key to filter data by
     */
    transformToMonthValueData(data, keys): StatisticsForecastCompareInterface {
        const currentYear = moment().year();
        const previousYear = moment().subtract(1, 'years').year();
        const current = new Array(12);
        const previous = new Array(12);
        const filtered = data.filter(item => keys === item.name);
        let updatedAt = null;
        for (let index = 0; index < current.length; index++) {
            const currentData = filtered.find(item => (
                item.yearMonth === parseInt(currentYear + (index + 1).toString().padStart(2, '0'), 10)
            ));
            current[index] = {
                month: (index + 1).toString().padStart(2, '0'),
                value: currentData ? Math.round(currentData.customValue) : 0,
            };
            if (!updatedAt) {
                updatedAt = currentData?.updated_at;
            } else {
                updatedAt = currentData?.updated_at > updatedAt ? currentData?.updated_at : updatedAt;
            }

            const previousData = filtered.find(item => (
                item.yearMonth === parseInt(previousYear + (index + 1).toString().padStart(2, '0'), 10)
            ));
            previous[index] = {
                month: (index + 1).toString().padStart(2, '0'),
                value: previousData ? Math.round(previousData.customValue) : 0,
            };
        }
        updatedAt = formatDateTimeToDate(updatedAt);

        return {previous, current, updatedAt};
    }

    /**
     * Returns an array of the custom values of the last three months from current month
     * array has form: [beforeLastMonth, lastMonth, thisMonth], where each month has {month, value}
     *
     * @param data - getStatistics() subscription
     * @param keys - StatisticTypeEnum
     * @param numberOfDecimalFigures - Number of decimal figures to round to
     * @param numberMonths - Number of months to return
     */
    transformToLastXMonths(data, keys, numberOfDecimalFigures = 0, numberMonths = 3) : {data: number[], updatedAt: string } {
        const lastMonths = [];
        const currentYearMonth = parseInt(moment().format('YYYYMM'), 10);
        let updatedAt = null;
        for (let i = 0; i < numberMonths; i++) {
            const prevYearMonth = i === 0 ? currentYearMonth : parseInt(moment().subtract(i,'months').format('YYYYMM'), 10);
            const findData = data.find(item => (keys === item.name && item.yearMonth === prevYearMonth));
            // rounds value with 2 decimal figures
            lastMonths[i] = findData ?
                Math.round(findData.customValue * Math.pow(10, numberOfDecimalFigures)) / Math.pow(10, numberOfDecimalFigures)
                : 0;
            if (!updatedAt) {
                updatedAt = findData?.updated_at;
            } else {
                updatedAt = findData?.updated_at > updatedAt ? findData?.updated_at : updatedAt;
            }
        }
        updatedAt = formatDateTimeToDate(updatedAt);

        return {data: lastMonths, updatedAt};
    }

    transformMonthlyData(data, key, isPreviousYear = false): MonthlyStatisticsInterface[] {
        const year = moment().subtract(isPreviousYear ? 1 : 0, 'year').year();

        const arrayTempl = [];
        for(let i=1;i<=12;i++) {
            arrayTempl.push({
                year,
                month: (i<10?'0'+i:i).toString(),
                value: 0
            });
        }

        const monthlyData = data
            .filter(item => item.name === key && moment(item.yearMonth, 'YYYYMM').year() === year)
            .map(item => ({
                year: moment(item.yearMonth, 'YYYYMM').year(),
                month: getMonthFromDate(item.yearMonth),
                value: item.customValue
            }));

        return arrayTempl
            .map(item => Object.assign(item, monthlyData.find(item2 => item2.month === item.month)))
            .sort((a,b) => a.month < b.month ? -1 : 1);
    }



    /**
     * Get cooperation bonus data
     */
    processCooperationBonus(data): CoopBonusInterface {
        // Cooperation Bonus
        const coopTargetSales = this.transformToLatestSingleValue(data, StatisticTypeEnum.TARGET_SALES);
        const numberOfParticipatedMonths = this.transformToLatestSingleValue(
            data,
            StatisticTypeEnum.COOPERATION_NUMBER_PARTICIPATED_MONTHS
        );
        const isCoopAvailable = coopTargetSales && coopTargetSales.customValue > 0 &&
                                numberOfParticipatedMonths && numberOfParticipatedMonths.customValue > 0;
        if(isCoopAvailable) {
            const currentMonth = moment(numberOfParticipatedMonths.yearMonth, 'YYYYMM').month();
            const coopRevenueCurrentYear = this.transformToLatestSingleValue(data, StatisticTypeEnum.COOPERATION_REVENUE_CURRENT_YEAR);
            const coopRevenueDiscountPercent = this.transformToCurrentMonthValue(data, StatisticTypeEnum.COOPERATION_REVENUE_DISCOUNT_PERCENT);
            return {
                isCoopAvailable,
                coopTargetSales: coopTargetSales.customValue || 0,
                coopRevenueDiscountPercent: coopRevenueDiscountPercent && coopRevenueDiscountPercent.customValue ?
                    coopRevenueDiscountPercent.customValue :
                    0,
                coopRevenueCurrentYear: coopRevenueCurrentYear && coopRevenueCurrentYear.customValue || 0,
                coopBonus: this.transformMonthlyData(data, StatisticTypeEnum.COOPERATION_REVENUE_LAST_MONTH),
                coopBonusPrevious: this.transformMonthlyData(data, StatisticTypeEnum.COOPERATION_REVENUE_LAST_MONTH, true),
                coopAnnualTargetPercent: coopRevenueCurrentYear && coopRevenueCurrentYear.customValue > 0
                                    && coopTargetSales.customValue > 0 ?
                                    coopRevenueCurrentYear.customValue * 100 / coopTargetSales.customValue :
                                    0,
                coopMonthlyTargetSales: coopTargetSales.customValue / (12 - currentMonth + numberOfParticipatedMonths.customValue),
                differenceCoopTarget: this.transformMonthlyData(data, StatisticTypeEnum.COOPERATION_REVENUE_TARGET_DIFFERENCE),
                updatedAt: formatDateTimeToDate(coopRevenueCurrentYear.updated_at)
            };
        }
        return null;
    }

    /**
     * Generate a unique id for the tables to prevent duplicate id's in html
     */
    generateUniqueTableId(): string {
        return 'statistic_table_' + Math.round(Math.random() * 10000000);
    }

    downloadXLSX(downloadName: string, uniqueID: string) {
        const sheetName = downloadName;
        const fileName = `${downloadName}.xlsx`;
        const workbook = utils.table_to_book(
            document.getElementById(uniqueID),
            {sheet:sheetName, raw: true}
        );
        writeFile(workbook, fileName);
    }
}
