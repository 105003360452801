import { AfterViewInit, Component, ElementRef, HostListener, Input } from '@angular/core';
import { StatisticDevelopmentType } from '../../enums/statistics-type.enum';

@Component({
    selector: 'app-icon-circle',
    templateUrl: './icon-circle.component.html',
    styleUrls: ['./icon-circle.component.scss'],
})
export class IconCircleComponent implements AfterViewInit {

    @Input() iconName = 'alert-outline';
    @Input() type = StatisticDevelopmentType.neutral;
    @Input() percentage = 0;

    width = 0;
    dashoffset = 0;

    @HostListener('window:resize', ['$event.target'])
    onResize() {
        this.width = this.el.nativeElement.offsetWidth;
        this.calculate();
    }


    constructor(private el:ElementRef) {

    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.width = this.el.nativeElement.offsetWidth;
            this.calculate();
        }, 500);
    }

    calculate() {
        // 1000 = 0% on the circle
        // lower than 1000 = depending on the width of the element, the 100% value must be calculated
        const fullCircle = 1000 - ((this.width) * 3.14159265359);
        const negFullCircle = Math.ceil(1000 - fullCircle);
        // Calculate dashoffset by percentage
        this.dashoffset = 1000 - (negFullCircle * (this.percentage / 100));

    }
}
