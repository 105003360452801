import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { QuillModule } from 'ngx-quill';
import { OAuthModule } from 'angular-oauth2-oidc';
import { FAVICON_CONFIG, FaviconModule } from '@codeculture/ng-favicon';
import { NgxHintsModule } from '@codeculture/ngx-hints';
import { register } from 'swiper/element/bundle';
import { MeaChatSconnectModule } from 'mea-chat-sconnect-libs';

import { CoreModule } from './core/core.module';
import { UiModule } from './ui/ui.module';
import { CustomizationModule } from './customization/customization.module';

import { StoreModule } from './core/store/store.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { CountryCodesEnum } from './core/core.enums';
import { SentryErrorHandler } from './sentry-error-handler.service';
import { QuillConfig } from './core/config/quill.config';
import { VersionCheckService } from './core/services/version-check.service';
import { VersionStateVar } from './core/store/locals/versionState.var';
import { SconnectAuthService } from './core/services/authentication/sconnect.auth.service';
import {
    ConnectionStatusService,
} from './core/services/connectionStatus.service';
import { environment as chatEnvironment } from '../../src/environments/mea-chat-sconnect/environments/environment';

// Register swiper components
register();


@Injectable()
export class ErrorHandlerClass extends SentryErrorHandler implements ErrorHandler {
    handleError(error) {
        if (environment.production) {
            // Show error message in console if maintenance mode is disabled
            if(localStorage.getItem('disable_maintenance_mode') === 'true') {
                console.error(error);
            }

            return super.handleError(error);
        }
        console.error(error);
        return new ErrorHandler();
    }
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({
            // enables innerHtml for e.g. ion-alert and ion-toast,
            // see https://ionicframework.com/docs/techniques/security#enabling-custom-html-parsing-via-innerhtml
            innerHTMLTemplatesEnabled: true,
        }),
        QuillModule.forRoot(QuillConfig),
        MeaChatSconnectModule.forRoot(chatEnvironment),
        AppRoutingModule,
        CoreModule,
        UiModule,
        CustomizationModule,
        StoreModule,
        ReactiveFormsModule,
        OAuthModule.forRoot(),
        FaviconModule,
        NgxHintsModule,
        IonicStorageModule.forRoot(),
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: APP_BASE_HREF, useValue: environment.baseHref },
        { provide: ErrorHandler, useClass: ErrorHandlerClass },
        { provide: FAVICON_CONFIG, useValue: {
            icons: {
                dotted: {
                    rel: 'icon',
                    type: 'image/png',
                    sizes: '64x64',
                    href: 'assets/icon/favicon.png'
                }
            }
        }},
        {
            provide: APP_INITIALIZER,
            useFactory: checkVersion,
            multi: true,
            deps: [VersionCheckService, VersionStateVar, SconnectAuthService]
        },
        ConnectionStatusService,

    ],
    exports: [],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA] // Required for Swiper elements
})
export class AppModule {
}

registerLocaleData(localeDE, CountryCodesEnum.DE);

export function checkVersion(
    versionCheckService: VersionCheckService,
    versionStateVar: VersionStateVar,
    sconnectAuthService: SconnectAuthService) {
    return () => new Promise(async resolve => {
        const isLoggedIn = await sconnectAuthService.isLoggedIn();
        const versionInfo = await versionCheckService.check();

        if (!versionInfo) {
            return;
        }

        // Do not update version if the user is logged in and refreshes
        // the page before the algorithm can handle the update
        if(!(isLoggedIn && versionInfo.forceReload && versionInfo.isNewVersionAvailable)) {
            versionStateVar.set({versionsId: versionInfo.latestVersion, reloadInformation: false});
        }
        if(!isLoggedIn) {
            if (versionInfo.isNewVersionAvailable) {
                await sconnectAuthService.logoutAndRefresh(false);
            }
            sconnectAuthService.checkIsLoggedOut().then(() => resolve(true));
        } else {
            resolve(true);
        }
    });
}
