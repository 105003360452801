import { computed, effect, inject, signal, untracked } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, combineLatest, debounceTime, map, Observable, switchMap, tap } from 'rxjs';
import { read, writeFile } from 'xlsx';
import { ToggleCustomEvent } from '@ionic/angular';
import isEqual from 'lodash/isEqual';
import {
    TableColumnInterface,
    TableFilterConfigInterface,
    TableSearchModalConfigInterface,
    TableSidebarInterface,
    TableSidebarItemsInterface,
    TableSortInterface
} from '../../../../../core/interfaces/table.interface';
import { TableFieldsEnum, TableFilterTypeEnum } from '../../../../../core/enums/table.enum';
import { defaultOrderFilters } from '../../../../../core/config/order-filters.config';
import {
    OrderDeliveryInterface,
    OrderDetailInterface,
    OrderFiltersInterface,
    OrderInterface
} from '../../../../../core/interfaces/order.interface';
import { DateSelectionEventInterface, DateSelectionFilterInterface } from '../../../../../core/interfaces/date-range.interface';
import { DateRangeConfig, ExpiryDateRangeConfig } from '../../../../../core/config/date-range.config';
import { SortDirectionEnum } from '../../../../../core/enums/sort-direction.enum';
import { OrderSortInterface } from '../../../../../core/interfaces/sorting.interface';
import { OrderQueries } from '../../../../../core/store/graphql/queries/order.graphql';
import { ToolbarStateVar } from '../../../../../core/store/locals/toolbarState.var';
import { GraphQLLimits } from '../../../../../core/config/graphql-limits.config';
import { ButtonTypes } from '../../../../../core/enums/button-actions.enum';
import { NotesInterface } from '../../../../../core/interfaces/notes.interface';
import { OrderTypesWithAllConfig } from '../../../../../core/config/order-types.config';
import { OrderStatusFilterConfig } from '../../../../../core/config/order-status.config';
import { AuthStorageKeyEnum } from '../../../../../core/enums/authStorageKey.enum';
import { ModalService } from '../../../../../core/services/modal.service';
import { OrderViewComponent } from '../widgets/order-view/order-view.component';
import {
    CommunicationZoneFormComponent
} from '../../../../communications/pages/communication-zone/widgets/communication-zone-form/communication-zone-form.component';
import { NoteWidgetComponent } from '../../../../../widgets/note-widget/note-widget.component';
import { NotesTypeEnum } from '../../../../../core/enums/notes.enum';
import { PrintPdfTableTypeEnum } from '../../../../../core/enums/tableToPdf.enum';
import { PdfProviderService } from '../../../../../core/services/pdfProvider.service';
import { ExportFormatEnum } from '../../../../../core/enums/export-format.enum';
import { ExcelQueries } from '../../../../../core/store/graphql/queries/excel.graphql';
import { formatDateTimeToDate, formatDateTimeToTime, getDate } from '../../../../../core/formatting/date.formatting';
import { ApiService } from '../../../../../core/services/api.service';
import { unsubscribe } from '../../../../../core/util/subscriptions.util';
import { BadgeTypeEnum } from '../../../../../core/enums/badge.enum';
import { ListFiltersInterface } from '../../../../../core/interfaces/filter-result.interface';
import { OrderService } from '../../../../../core/services/order.service';
import { OrderCopyComponent } from '../widgets/order-copy/order-copy.component';
import { DateRangeOptionCodes } from '../../../../../core/enums/date-range.enum';
import { StatisticTypeEnum } from '../../../../../core/enums/statistics-type.enum';
import { OrderStatus, OrderStatusFilter, OrderType } from '../../../../../core/enums/orders.enum';
import { getOrderReplacementArray } from '../../../../../core/config/communication-zone-forms.config';
import { CommunicationZoneType } from '../../../../../core/core.enums';
import { UpdatesTimeframeFilterConfig } from '../../../../../core/config/updates-timeframe-filter.config';

/**
 * The orders service is not provided application wide but on a component level. If a component uses this service, use it as a provider in the
 * component annotation.
 */
export class OrdersService {
    private storage = inject(Storage);
    private orderQueries = inject(OrderQueries);
    private excelQueries = inject(ExcelQueries);
    private toolbarState = inject(ToolbarStateVar);
    private modalService = inject(ModalService);
    private apiService = inject(ApiService);
    private tableToPdfService = inject(PdfProviderService);
    private orderService = inject(OrderService);
    private isInitialized = new BehaviorSubject<boolean>(false);


    public readonly columns: TableColumnInterface[] = [
        // tslint:disable-next-line:max-line-length
        {id: 1, title: 'Typ',               width: '4.5rem',  sortable: true, class: 'first-col-padding ion-text-center ion-justify-content-center', dataKey: TableFieldsEnum.type},
        {id: 2, title: 'Name',              width: '11.5rem', sortable: true, class:'col-auto', dataKey: TableFieldsEnum.productName},
        {id: 3, title: 'Bestellt',          width: '8rem',    sortable: true, dataKey: TableFieldsEnum.recTime},
        {id: 4, title: 'Herst. liefert ab', width: '10rem',   sortable: true, dataKey: TableFieldsEnum.deliveryDate, ttKey: 'order_estimatedDeliveryDate'},
        {id: 5, title:  'Änd.-Datum',       width: '8rem',    sortable: true, dataKey: TableFieldsEnum.lastUpdate},
        {id: 6, title: 'Status',            width: '8.5rem',  sortable: true, dataKey: TableFieldsEnum.status},
        {id: 7, title: 'Notiz',             width: '3rem',    sortable: true, dataKey: TableFieldsEnum.notes},
        {id: 8, title: '',                  width: '3rem',    sortable: false, dataKey: TableFieldsEnum.notes},
        {id: 9, title: '',                  width: '2rem',    sortable: false},
    ];

    /* ################ filters ################ */
    private _filters = signal<OrderFiltersInterface>(defaultOrderFilters);
    public filters = this._filters.asReadonly();
    public orderDateFilter = computed<DateSelectionFilterInterface>(() => {
        const filters = this.filters();
        return {
            label: 'Bestelldatum',
            selectedValue: filters?.recTimeOption,
            selectedValues: {
                dateOption: filters?.recTimeOption,
                dateRangeOptions: DateRangeConfig,
                dateFrom: filters?.recTimeFrom,
                dateTo: filters?.recTimeTo,
            },
        };
    });
    public expiryDateFilter = computed<DateSelectionFilterInterface>(() => {
        const filters = this.filters();
        return {
            label: 'Ablaufdatum',
            selectedValue: filters?.expiryDateOption,
            selectedValues: {
                dateOption: filters?.expiryDateOption,
                dateRangeOptions: ExpiryDateRangeConfig(),
                dateFrom: filters?.expiryDateFrom,
                dateTo: filters?.expiryDateTo,
            },
        };
    });

    /* ################ sort ################ */
    private _sort = signal<TableSortInterface>({
        field: TableFieldsEnum.type,
        order: SortDirectionEnum.asc,
        onUpdate: (field: TableFieldsEnum) => {
            this.updateSort(field);
        }
    });
    public sort = this._sort.asReadonly();

    /**
     * Used to keep track of the current offset of the table when lazy loading is enabled and data is loaded during scrolling
     * @private
     */
    private _currentOffset = signal<number>(0);
    private _currentOffset$ = toObservable(this._currentOffset);

    /* ################ orders data ################ */
    private _orders$ = combineLatest([toObservable(this._filters), toObservable(this._sort), this.isInitialized])
        .pipe(debounceTime(200))
        .pipe(switchMap(([filters, sort, isInitialized]) => {
            if(!isInitialized){
                return [];
            }
            const ordersSort: OrderSortInterface = {
                field: sort.field,
                direction: sort.order
            };
            // Filters or Sort has changed, so we need to reset the offset
            this._currentOffset.set(0);
            return this.orderQueries.getOrdersCount(filters).pipe(
                switchMap(totalRows => {
                    const fullArray = new Array(totalRows).fill(null);
                    void this.toolbarState.setPageInformation(totalRows, null);

                    // Only offset changes
                    return this._currentOffset$.pipe(
                        switchMap(offset => {
                            return this.orderQueries.getOrders(filters, offset, GraphQLLimits.order, ordersSort).pipe(
                                map(orders => {
                                    fullArray.splice(offset, orders.length, ...orders);
                                    return fullArray;
                                })
                            );
                        }),
                        tap(() => {
                            this._isLoading.set(false);
                        })
                    );
                })
            );
        })
    );
    public orders = toSignal<OrderInterface[]>(this._orders$, {initialValue: null});

    /* ################ selected order data (details) ################ */
    private _orderById = signal<OrderDetailInterface>(null);
    public orderById = this._orderById.asReadonly();
    private _activeOrderId = signal<number>(null);
    public activeOrderId = this._activeOrderId.asReadonly();
    private _ignoreOrders = signal<boolean>(false);
    public ignoreOrders = this._ignoreOrders.asReadonly();
    private _sidebar$: Observable<TableSidebarInterface> = toObservable(this._activeOrderId).pipe(
        debounceTime(200),  // debounce to prevent multiple requests when the active order id changes within a very small time frame
        switchMap(id => {
            if (id) {
                return this.orderQueries.getOrderById(id).pipe(
                    tap(orderById => this._orderById.set(orderById)),
                    map(orderById => {
                        if (!orderById) {
                            return null;
                        }
                        return {
                            title: orderById.orderIdOrg,
                            subTitle: 'Bestell-#',
                            actionPopoverItems: [{
                                label: 'Details',
                                code: ButtonTypes.VIEW,
                                onAction: () => this.openDetailsModal()
                            }, {
                                label: 'Ware angekommen',
                                code: ButtonTypes.ACCEPT,
                                onAction: () => this.orderService.presentAcknowledgeAlert(orderById),
                                isDisabled: !this.orderService.isAcknowledgeEnabled(orderById),
                                tooltipKey: this.orderService.acknowledgeButtonTooltip(orderById)
                            }, {
                                label: 'Stornieren',
                                code: ButtonTypes.CANCEL,
                                onAction: () => this.orderService.presentCancelAlert(orderById),
                                isDisabled: !this.orderService.isCancellationEnabled(orderById),
                                tooltipKey: this.orderService.cancellationButtonTooltip(orderById)
                            }, {
                                label: 'Nachbestellen',
                                code: ButtonTypes.COPY,
                                onAction: () => this.openOrderCopyModal(),
                                isDisabled: !this.orderService.isCopyEnabled(orderById),
                                tooltipKey: this.orderService.copyButtonTooltip(orderById)
                            }, {
                                label: 'Verlängern',
                                code: ButtonTypes.EXTEND,
                                onAction: () => this.orderService.presentExtensionAlert(orderById),
                                isDisabled: !this.orderService.isExtensionEnabled(orderById),
                                tooltipKey: this.orderService.extensionButtonTooltip(orderById)
                            }, {
                                label: 'Interne Notiz bearbeiten',
                                code: ButtonTypes.NOTE,
                                onAction: () => this.openNoteModal()
                            }, {
                                label: 'Kundenservice kontaktieren',
                                code: ButtonTypes.SUPPORT,
                                tooltipKey: 'order_support_button',
                                onAction: () => this.openSupportModal()
                            }],
                            sidebarItems: this.buildSidebarItems(orderById),
                            previousRow: () => this.updateActiveOrderId(-1),
                            nextRow: () => this.updateActiveOrderId(1)
                        };
                    }),
                );
            }
            return new Observable<TableSidebarInterface>(subscriber => {
                subscriber.next(null);
            });
        })
    );
    public sidebar = toSignal(this._sidebar$, {initialValue: null});

    /* ################ initial loading state (when page is first rendered) ################ */
    private _isLoading = signal<boolean>(true);
    public isLoading = this._isLoading.asReadonly();

    /* ################ notes attached to each order ################ */
    private _notes = signal<NotesInterface[]>([]);
    private _notes$ = toObservable(this.orders).pipe(
        switchMap(orders => {
            if (orders?.length) {
                const ids = orders.filter(o => o?.id).map(o => o?.id);
                return this.orderQueries.getOrderNotesByOrderIds(ids);
            } else {
                return new Observable<NotesInterface[]>(observer => {
                    observer.next([]);
                });
            }
        }),
        tap(notes => {
            this._notes.set(notes);
        })
    );
    public notes = toSignal(this._notes$, {initialValue: []});
    public notesByOrderId = computed(() => {
        const orderById = this._orderById();
        const notes = this._notes();
        return notes.find(note => note.orderId === orderById?.id);
    });

    /* ################ autocomplete search for pzn ################ */
    private _pznSearch = signal('');
    public pznSearch = this._pznSearch.asReadonly();
    private pznList$ = toObservable(this._pznSearch).pipe(
        switchMap((searchTerm) => {
            if(searchTerm?.length) {
                return this.orderQueries.getPZNCollection(searchTerm);
            }
            return new Observable<string[]>(subscriber => {
                subscriber.next([]);
            });
        })
    );
    public pznList = toSignal(this.pznList$, {initialValue: []});

    /* ################ autocomplete search for producers ################ */
    private _producerSearch = signal('');
    public producerSearch = this._producerSearch.asReadonly();
    private _producersList$ = toObservable(this._producerSearch).pipe(
        switchMap((searchTerm) => {
            if(searchTerm?.length) {
                return this.orderQueries.getProducerCollection(searchTerm);
            }
            return new Observable<string[]>(subscriber => {
                subscriber.next([]);
            });
        })
    );
    public producersList = toSignal(this._producersList$, {initialValue: []});

    private storageKeys = {
        filters: 'ordersTableFilters',
        sorting: 'ordersTableSorting'
    };

    /**
     * Configure the filters for the orders table
     */
    public filterLeftConfig: TableFilterConfigInterface[] = [
        {
            label: 'Bestelldatum',
            type: TableFilterTypeEnum.date,
            dataKey: 'recTime',
            dateSignal: this.orderDateFilter,
            selectableValues: DateRangeConfig,
            onAction: (newFilter: DateSelectionEventInterface) => {
                this.updateDateFilter(newFilter, 'recTime');
            }
        },
        {
            label: 'Ablaufdatum',
            type: TableFilterTypeEnum.date,
            dataKey: 'expiryDate',
            dateSignal: this.expiryDateFilter,
            selectableValues: ExpiryDateRangeConfig(),
            onAction: (newFilter: DateSelectionEventInterface) => {
                this.updateDateFilter(newFilter, 'expiryDate');
            }
        },
        {
            label: 'Typ',
            type: TableFilterTypeEnum.select,
            dataKey: 'type',
            selectableValues: OrderTypesWithAllConfig,
            onAction: (newFilter: string, filterKey: string) => {
                this.updateSelectFilter(newFilter, filterKey);
            }
        },
        {
            label: 'Status',
            type: TableFilterTypeEnum.multiSelect,
            dataKey: 'status',
            selectableValues: OrderStatusFilterConfig,
            onAction: (newFilter: string, filterKey: string) => {
                this.updateSelectFilter(newFilter, filterKey);
            }
        },
        {
            label: 'Nur Aktualisierungen',
            type: TableFilterTypeEnum.trueFalse,
            dataKey: 'updatesOnly',
            onAction: (filterEvent: ToggleCustomEvent, filterKey: string) => {
                this.updateBoolFilter(filterEvent.detail.checked, filterKey);
            }
        },
        {
            label: 'Aktualisierungen von',
            type: TableFilterTypeEnum.select,
            selectableValues: UpdatesTimeframeFilterConfig,
            dataKey: 'updatesUntil',
            isVisible: computed(() => this.filters().updatesOnly),
            onAction: (newFilter: string, filterKey: string) => {
                this.updateSelectFilter(newFilter, filterKey);
            }
        },
        {
            label: 'Suche',
            type: TableFilterTypeEnum.search,
            dataKey: 'search'
        }
    ];

    /* ################ filters on the right side (above the sidebar) ################ */
    private _printLoading = signal(false);
    private _printEnabled = signal(true);
    private _downloadLoading = signal(false);
    public filterRightConfig: TableFilterConfigInterface[] = [
        {
            icon: 'print-outline',
            type: TableFilterTypeEnum.print,
            onAction: () => this.onPrint(),
            payload: {
                isLoading: this._printLoading,
                isEnabled: this._printEnabled
            }
        },
        {
            icon: 'download-outline',
            type: TableFilterTypeEnum.download,
            onAction: (event) => this.onDownload(event),
            payload: {
                trigger: 'download-action',
                isLoading: this._downloadLoading
            }
        }
    ];

    /* ################ search modal filters configuration ################ */
    public SearchModalFilterConfig: TableSearchModalConfigInterface = {
        title: 'Aufträge filtern',
        onAction: (newFilters: OrderFiltersInterface) => this.onSearchModalAction(newFilters),
        onReset: () => this.onSearchModalCancel(),
        items: [
            {
                label: 'Suche',
                type: TableFilterTypeEnum.search,
                dataKey: 'search',
            },
            {
                label: 'Bestelldatum',
                type: TableFilterTypeEnum.date,
                dataKey: 'recTime',
                selectableValues: DateRangeConfig,
                dateSignal: this.orderDateFilter,
                onAction: (newFilter: DateSelectionEventInterface) => {
                    this.updateDateFilter(newFilter, 'recTime');
                }
            },
            {
                label: 'Ablaufdatum',
                type: TableFilterTypeEnum.date,
                dataKey: 'expiryDate',
                selectableValues: ExpiryDateRangeConfig(),
                dateSignal: this.expiryDateFilter,
                onAction: (newFilter: DateSelectionEventInterface) => {
                    this.updateDateFilter(newFilter, 'expiryDate');
                }
            },
            {
                label: 'Typ',
                type: TableFilterTypeEnum.select,
                dataKey: 'type',
                selectableValues: OrderTypesWithAllConfig
            },
            {
                label: 'Status',
                type: TableFilterTypeEnum.multiSelect,
                dataKey: 'status',
                selectableValues: OrderStatusFilterConfig
            },
            {
                label: 'PZN',
                type: TableFilterTypeEnum.inputSelect,
                dataKey: 'pzn',
                selectableSignal: this.pznList,
                secondarySignal: this.pznSearch,
                setSecondarySignal: (value: string) => this.setPZNSearch(value)
            },
            {
                label: 'Hersteller',
                type: TableFilterTypeEnum.inputSelect,
                dataKey: 'producer',
                selectableSignal: this.producersList,
                secondarySignal: this.producerSearch,
                setSecondarySignal: (value: string) => this.setProducerSearch(value)
            }
        ]
    };

    constructor() {
        const activePharmacy = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);

        /**
         * Set values of pznSearch, producerSearch and deliveryNoteNumberSearch as soon as the filters are loaded
         */
        effect(() => {
            const filters = this.filters();
            untracked(() => {
                this._pznSearch.set(filters.pzn);
                this._producerSearch.set(filters.producer);
            });
        });

        /**
         * Set the active order id to the first order in the list as soon as the orders are initially loaded
         */
        effect(() => {
            // Only override active order id if orders shouldn't be ignored
            if(!this.ignoreOrders()) {
                const orders = this.orders();
                if ((orders?.length && this._activeOrderId() === null)
                || orders?.length && !orders.find(order => order?.id === this._activeOrderId())) {
                    untracked(() => {
                        this._activeOrderId.set(orders[0]?.id);
                    });
                } else if (!orders?.length) {
                    untracked(() => {
                        this._activeOrderId.set(null);
                    });
                }
            }
        });

        /**
         * Save the filters to the storage as soon as they change
         */
        effect(() => {
            if(this._filters()) { // required, so the effect gets triggered
                this.storage.get(`${this.storageKeys.filters}_${activePharmacy}`).then((filters) => {
                    if (!isEqual(filters, this._filters())) {
                        void this.storage.set(`${this.storageKeys.filters}_${activePharmacy}`, this._filters());
                    }
                });
            }
        });

        /**
         * Save the sorting to the storage as soon as it changes
         */
        effect(() => {
            if(this._sort()) { // required, so the effect gets triggered
                this.storage.get(`${this.storageKeys.sorting}_${activePharmacy}`).then((sort) => {
                    if (!isEqual(sort, this._sort())) {
                        void this.storage.set(`${this.storageKeys.sorting}_${activePharmacy}`, {
                            field: this._sort().field,
                            order: this._sort().order
                        });
                    }
                });
            }
        });
    }

    /**
     * Initialize the service
     *
     * This method is called in the component's ngOnInit lifecycle hook. It initializes the
     * service by loading the filters and sorting from the storage.
     *
     * Notice: This is not required, if the view doesn't contain any orders
     * (e.g. notification widget, which only loads a single order for detail modal).
     */
    init() {
        const activePharmacy = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
        /**
         * Load the filters and sorting from the storage
         */
        Promise.all([
            this.storage.get(`${this.storageKeys.filters}_${activePharmacy}`),
            this.storage.get(`${this.storageKeys.sorting}_${activePharmacy}`)
        ]).then(([filters, sorting]) => {
            if(filters) {
                this._filters.update(prev => ({
                    ...prev,
                    ...filters,
                }));
            }
            if (sorting) {
                this._sort.update(prev => ({
                    ...prev,
                    ...sorting
                }));
            }
            this.isInitialized.next(true);
        });
    }

    /**
     * Update the date filter
     * @param newFilter the new date filter
     * @param keyPrefix prefix of the attribute keys, e.g. 'date' or 'deliveryNoteDate'
     */
    public updateDateFilter(newFilter: DateSelectionEventInterface, keyPrefix = 'date') {
        this._filters.update(prev => {
            return {
                ...prev,
                [keyPrefix + 'Option']: newFilter?.dateOption,
                [keyPrefix + 'From']: newFilter?.dateFrom,
                [keyPrefix + 'To']: newFilter?.dateTo,
            };
        });
    }

    /**
     * Save filters that are selected using a popover.
     * The filters are saved to the storage by using an effect to automatically save the filters as soon as they change.
     * @param newFilter string value of the new filter
     * @param filterKey key of the filter that is being updated
     */
    public updateSelectFilter(newFilter: string, filterKey: string) {
        this._filters.update(prev => {
            return {
                ...prev,
                [filterKey]: newFilter,
            };
        });
    }

    /**
     * Save filters that are set via checkbox or any other true/false element.
     * The filters are saved to the storage by using an effect to automatically save the filters as soon as they change.
     * @param isTrue boolean value of the new filter
     * @param filterKey key of the filter that is being updated
     */
    public updateBoolFilter(isTrue: boolean, filterKey: string) {
        this._filters.update(prev => {
            return {
                ...prev,
                [filterKey]: isTrue,
            };
        });
    }

    /**
     * Change the sorting of the table.
     * The sorting is saved to the storage by using an effect to automatically save the sorting as soon as it changes.
     * @param field
     */
    public updateSort(field: TableFieldsEnum) {
        this._sort.update(prev => ({
            ...prev,
            field,
            order: this._sort().order === SortDirectionEnum.asc ? SortDirectionEnum.desc : SortDirectionEnum.asc,
        }));
    }

    public updateOffset(offset: number) {
        this._currentOffset.set(offset);
    }

    /**
     * Set the active order id
     *
     * Notice: Use for views without list of orders (e.g. notification widget). Otherwise,
     * the active id might be overwritten by the first order in the list.
     *
     * @param id
     * @param ignoreOrders
     */
    public setActiveOrderId(id: number, ignoreOrders = false) {
        if(ignoreOrders) {
            this._ignoreOrders.set(ignoreOrders);
        }
        this._activeOrderId.set(id);
    }

    public updateActiveOrderId(upDown: number) {
        const selectedRowIndex = this.orders().findIndex(item => item?.id ? item.id === this._activeOrderId() : false);
        const newSelectedId = this.orders()[selectedRowIndex + upDown]?.id || this._activeOrderId();
        this._activeOrderId.set(newSelectedId);
    }

    public updateFilters(newFilters: ListFiltersInterface) {
        this._filters.update(filters => ({...filters, ...newFilters}));
    }

    private onSearchModalAction(newFilters: OrderFiltersInterface) {
        this._filters.update(prev => {
            return {
                ...prev,
                ...newFilters,
                recTimeOption: newFilters['recTimeOption'] || DateRangeOptionCodes.all,
                recTimeFrom: newFilters['recTimeFrom'],
                recTimeTo: newFilters['recTimeTo'],
            };
        });
    }

    private onSearchModalCancel() {
        this._filters.set(defaultOrderFilters);
    }

    private setPZNSearch(search: string) {
        this._pznSearch.set(search);
    }

    private setProducerSearch(search: string) {
        this._producerSearch.set(search);
    }

    async openDetailsModal(highlightChanges = false) {
        const modal = await this.modalService.create(
            OrderViewComponent,
            {
                details: this.orderById,
                sidebar: this.sidebar,
                notesById: this.notesByOrderId,
                highlightChanges
            }
        );
        return await this.modalService.present(modal);
    }

    private async openOrderCopyModal() {
        const modal = await this.modalService.create(
            OrderCopyComponent,
            {orderId: this.orderById().id}
        );
        await this.modalService.present(modal);
    }

    private async openSupportModal() {
        const modal = await this.modalService.create(
            CommunicationZoneFormComponent,
            {
                id: this.orderById().id,
                type: CommunicationZoneType.ORDER,
                isReadOnly: true,
                replaceArray: getOrderReplacementArray(this.orderById())
            }
        );
        await this.modalService.present(modal);
    }

    private async openNoteModal() {
        const modal = await this.modalService.create(
            NoteWidgetComponent,
            {
                noteType: NotesTypeEnum.order,
                sourceId: this.orderById().id
            }
        );
        await this.modalService.present(modal);
    }

    /**
     * Open new browser tab and print table as PDF.
     */
    private async onPrint() {
        this._printEnabled.set(false);
        this._printLoading.set(true);
        // disable print button for 30 seconds
        setTimeout(() => {
            this._printEnabled.set(true);
        }, 30000);

        await this.tableToPdfService.getTablePdf(PrintPdfTableTypeEnum.orders, this.filters());
        this._printLoading.set(false);
    }

    /**
     * Convert table values to excel or csv and download the file.
     */
    private async onDownload(format: ExportFormatEnum = ExportFormatEnum.XLSX) {
        this._downloadLoading.set(true);
        const subscription = this.excelQueries.exportExcelOrders(this.filters()).subscribe(res => {
            if(res?.data && res?.filename) {
                const buf = res.data.split(',').map(n => parseInt(n, 10));
                const wb = read(Uint8Array.from(buf).buffer, {type: 'buffer'});

                const date = '_' + getDate();

                switch(format) {
                    case ExportFormatEnum.XLSX:
                        writeFile(wb, res?.filename + date + '.xlsx', {compression: true});
                        break;
                    case ExportFormatEnum.CSV:
                        writeFile(wb, res?.filename+ date + '.csv', {bookType: 'csv'});
                        break;
                }
            } else if(res?.status === 'ERROR') {
                void this.apiService.presentErrorToast(null, res.message);
            }
            unsubscribe(subscription);
        });
        this._downloadLoading.set(false);
    }


    getDeliveriesCompleted(orderDeliveries: Array<OrderDeliveryInterface>): string {
        if (orderDeliveries && orderDeliveries.length) {
            const totalQuantity = orderDeliveries.reduce((total, delivery) => {
                return delivery.status === 'COMPLETED' ? total + delivery.quantity : total;
            }, 0);
            return totalQuantity.toString();
        }
        return '0';
    }
    private buildSidebarItems(orderById: OrderDetailInterface): TableSidebarItemsInterface[]{
        const deliveriesBadge = orderById.status === OrderStatus.PARTIALLY_COMPLETED ? this.getDeliveriesCompleted(orderById.orderDeliveries) : null;
        return [
            {
                key: 'status',
                value: orderById.status,
                colWidth: '37%',
                badgeType: BadgeTypeEnum.ORDER
            },
            {
                key: 'partiallyDeliveries',
                value: deliveriesBadge,
                colWidth: '50%',
                badgeType: BadgeTypeEnum.DELIVERED_ITEMS,
            },
            {
                key: 'last_update',
                value: formatDateTimeToDate(orderById.last_update, true),
                label: 'Änderungsdatum',
                colWidth: '100%',
            },
            {
                key: 'expectedDelivery',
                value: orderById.expectedDelivery,
                label: 'erwarteter Liefertermin',
                colWidth: '100%'
            },{
                key: 'recTime',
                value: formatDateTimeToDate(orderById.recTime, true) + ' - ' + formatDateTimeToTime(orderById.recTime, false),
                label: 'Bestelldatum',
                colWidth: orderById.expiryDate !== null ? '50%' : '100%'
            },{
                key: 'expiryDate',
                value: formatDateTimeToDate(orderById.expiryDate, true),
                label: 'Ablaufdatum',
                colWidth: '50%',
                isVisible: orderById.expiryDate !== null
            },{
                key: 'estimatedDeliveryDate',
                value: formatDateTimeToDate(orderById.estimatedDeliveryDate, true),
                label: 'Hersteller liefert ab',
                colWidth: '100%',
                isVisible: orderById.estimatedDeliveryDate !== null,
                ttKey: 'order_estimatedDeliveryDate'
            },
            {
                key: 'productName',
                value: orderById.productName,
                label: 'Name',
                colWidth: '100%',
            },
            {
                key: 'packageSize',
                value: orderById.packageSize,
                label: 'Packungsgröße',
                colWidth: '50%',
            },
            {
                key: 'dosageForm',
                value: orderById.dosageForm,
                label: 'Darreichung',
                colWidth: '50%',
            },
            {
                key: 'pzn',
                label: 'PZN',
                value: orderById.pzn.padStart(8, '0'),
                colWidth: '100%',
                onAction: () => {
                    void this.modalService.dismiss();
                    this._filters.update(prev => ({
                        ...prev,
                        pzn: orderById.pzn
                    }));
                }
            },
            {
                key: 'producer',
                label: 'Hersteller',
                value: orderById.producer,
                colWidth: '100%',
                onAction: () => {
                    void this.modalService.dismiss();
                    this._filters.update(prev => ({
                        ...prev,
                        producer: orderById.producer
                    }));
                }
            },
            {
                key: 'isNarcotic',
                value: orderById.isNarcotic ? 'Ja' : 'Nein',
                label: 'Betäubungsmittel',
                colWidth: '100%',
            }
        ];
    }


    handleRouteParams(params: { [x: string]: any; }) {
        let filters = null;
        if(params['timeframe']) {
            filters = {
                updatesOnly: true,
                updatesUntil: params['timeframe']
            };
        }

        if(params['statisticType']) {
            switch(params['statisticType']) {
                case StatisticTypeEnum.ORDERS_OPEN:
                    const filterGroup = OrderStatusFilterConfig.find(statusFilter => statusFilter.id === OrderStatusFilter.OPEN);
                    filters = {
                        expiryDateOption: DateRangeOptionCodes.all,
                        orderDateOption: DateRangeOptionCodes.all,
                        type: OrderType.ALL,
                        status: [...filterGroup.statusIds, filterGroup.id].join(','),
                        producer: '',
                        pzn: '',
                        search: ''
                    };
                    break;
                case StatisticTypeEnum.ORDERS_ENROUTE:
                    filters = {
                        expiryDateOption: DateRangeOptionCodes.all,
                        orderDateOption: DateRangeOptionCodes.all,
                        type: OrderType.ALL,
                        status: OrderStatus.ENROUTE,
                        producer: '',
                        pzn: '',
                        search: ''
                    };
                    break;
            }
        }
        if(filters) {
            // we have to add a delay, otherwise the table might stay empty
            setTimeout(() => {
                this.updateFilters(filters);
            }, 500);
        }
    }
}
