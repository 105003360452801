import { Component, HostListener, inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Validators, FormBuilder, FormGroup } from '@angular/forms';
// Core Files
import { QuantityValidator } from '../../../../../../core/core.validators';
import { OrdersMutations } from '../../../../../../core/core.store';
import { ContactPersonService, InputValidationService, ModalService, OrderService } from '../../../../../../core/core.services';
import {
    ModalClassEnum,
    FieldLengthEnum,
    matomoIdsEnum,
    InputValidationFieldEnum
} from '../../../../../../core/core.enums';
import { FieldLengths, TOOLTIPS } from '../../../../../../core/core.config';
import { ToastService } from '../../../../../../core/services/toast.service';
import { unsubscribeAll } from '../../../../../../core/util/subscriptions.util';

@Component({
    selector: 'app-disposition-request',
    templateUrl: './disposition-request.component.html',
    styleUrls: ['./disposition-request.component.scss'],
})
export class DispositionRequestComponent implements OnInit {

    static modalClass = ModalClassEnum.medium;

    private orderMutations = inject(OrdersMutations);
    private formBuilder = inject(FormBuilder);
    private modalService = inject(ModalService);
    private validationService = inject(InputValidationService);
    private contactPersonService = inject(ContactPersonService);
    private toastService = inject(ToastService);

    matomoId = matomoIdsEnum.ordersDispoRequest;

    // Popover Controller, provided by ionic
    popover;
    tooltips = TOOLTIPS;

    qty: number;

    validators = this.validationService.communicationZoneValidators;
    validatorSubscription: Subscription;
    validationFormGroup: FormGroup;
    validationFieldsEnum = InputValidationFieldEnum;
    fieldLengths = {
        dispoEnquiry: FieldLengths[FieldLengthEnum.dispoEnquiry],
        qty: (Math.pow(10, FieldLengths[FieldLengthEnum.dispoEnquiryQty]) - 1),
        productName: FieldLengths[FieldLengthEnum.dispoEnquiryProductName],
        isNarcotic: FieldLengths[FieldLengthEnum.dispoEnquiryNarcotic],
        packageSize: FieldLengths[FieldLengthEnum.dispoEnquiryPackageSize],
        dosageForm: FieldLengths[FieldLengthEnum.dispoEnquiryDosageForm],
        producer: FieldLengths[FieldLengthEnum.dispoEnquiryProducer],
        pzn: FieldLengths[FieldLengthEnum.dispoEnquiryPZN],
        additionalInformation: FieldLengths[FieldLengthEnum.dispoEnquiryAdditionalInformation]
    };
    contactNames: Array<string>;

    @HostListener('document:keyup.Enter', ['$event']) onArrowUpHandler(event: KeyboardEvent) {
        if(this.validationFormGroup.valid) {
            void this.sendDispositionRequest(this.validationFormGroup.value);
        }
    }

    ngOnInit() {
        this.setValidationFormGroup();

        this.contactNames = this.contactPersonService.get();
    }

    ionViewWillLeave() {
        unsubscribeAll([
            this.validatorSubscription
        ]);
    }

    /**
     * validate the form data
     */
    setValidationFormGroup() {
        this.validationFormGroup = this.formBuilder.group({
            contactPerson: ['', this.validationService.getFormValidators(InputValidationFieldEnum.contactPerson, this.validators)],
            qty: ['', [InputValidationService.noWhitespaceValidator, ...QuantityValidator(false, this.fieldLengths.qty)]],
            productName: ['', [InputValidationService.noWhitespaceValidator, Validators.maxLength(this.fieldLengths.productName)]],
            isNarcotic: [false, [InputValidationService.noWhitespaceValidator, Validators.maxLength(this.fieldLengths.isNarcotic)]],
            packageSize: ['', [InputValidationService.noWhitespaceValidator, Validators.maxLength(this.fieldLengths.packageSize)]],
            dosageForm: ['', [InputValidationService.noWhitespaceValidator, Validators.maxLength(this.fieldLengths.dosageForm)]],
            producer: ['', [InputValidationService.noWhitespaceValidator, Validators.maxLength(this.fieldLengths.producer)]],
            pzn: ['', [InputValidationService.noWhitespaceValidator, Validators.maxLength(this.fieldLengths.pzn)]],
            additionalInformation: ['', [
                InputValidationService.noWhitespaceValidator,
                Validators.maxLength(this.fieldLengths.additionalInformation)]
            ]
        }, {validators: this.minimumFieldsFilled('additionalInformation', 'qty', 'productName', 'pzn', 'contactPerson')});
    }

    /**
     * check fields that at least one of the fields is filled
     *
     * @param additionalInformation : string
     * @param qty : string
     * @param productName : string
     * @param pzn : string
     * @param contactPerson : string
     */
    minimumFieldsFilled(additionalInformation: string, qty: string, productName: string, pzn: string, contactPerson: string) {
        return (group: FormGroup): {[key: string]: any} => {
            const additionalInformationElement = group.controls[additionalInformation];
            const qtyElement = group.controls[qty];
            const productNameElement = group.controls[productName];
            const pznElement = group.controls[pzn];
            const contactPersonElement = group.controls[contactPerson];

            if (
                additionalInformationElement.value.length === 0 &&
                !(contactPersonElement.value.length > 0 && qtyElement.value > 0
                    && ( productNameElement.value.length > 0 || pznElement.value.length > 0)
                )
            ) {
                return {
                    minimumFieldsFilled: true
                };
            }
        };
    }

    /**
     * send the disposition request via api call
     *
     * @param values : array
     */
    sendDispositionRequest(values) {
        let contents = 'Ansprechpartner: ' + values.contactPerson + '\n';
        contents += 'Menge: ' + OrderService.formatInput(values.qty) + '\n';
        contents += 'Produktname: ' + OrderService.formatInput(values.productName) + '\n';

        contents += 'Betäubungsmittel: ';
        contents +=  values.isNarcotic ? 'Ja' : 'Nein';
        contents +=  '\n';

        contents += 'Packungseinheit: ' + OrderService.formatInput(values.packageSize) + '\n';
        contents += 'Darreichung: ' + OrderService.formatInput(values.dosageForm) + '\n';
        contents += 'Hersteller: ' + OrderService.formatInput(values.producer) + '\n';
        contents += 'PZN: ' + OrderService.formatInput(values.pzn) + '\n';
        contents += 'Weitere Informationen: ' + OrderService.formatInput(values.additionalInformation);
        if (contents.length > this.fieldLengths.dispoEnquiry) {
            return this.toastService.presentWarning(
                'Bitte kürzen Sie Ihren Text auf maximal ' + this.fieldLengths.dispoEnquiry + ' Zeichen.'
            );
        }

        void this.contactPersonService.set(values.contactPerson);

        // do not call the formatInput-function for productName - if empty, it will be converted to null in dispoEnquiry()
        this.orderMutations.dispoEnquiry(contents, values.productName, values.contactPerson);
        void this.modalService.dismiss();
    }
}
