import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { from, map } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { BusinessFigurePdfTypeEnum } from '../../../enums/statistics.enum';

export const RequestBusinessFigurePdfMutation = gql`
    mutation RequestBusinessFigurePdf($type: BusinessFigurePdfTypes!, $fromDate: String!, $toDate: String!, $producerId: Float) {
        RequestBusinessFigurePdf(type: $type, fromDate: $fromDate, toDate: $toDate, producerId: $producerId) {
            message
            status
        }
    }
`;

export const InsertBusinessFigureSubscriptions = gql`
    mutation insertBusinessFigureSubscriptions($objects: [subscriptions_insert_input!]!) {
        insert_subscriptions(objects: $objects, on_conflict: {constraint: subscriptions_type_subType_pharmacyStoreId_key}) {
            affected_rows
        }
    }
`;

export const DeleteBusinessFigureSubscription = gql`
    mutation deleteBusinessFigureSubscriptions($types: [String!]!) {
        delete_subscriptions(where: {_and: [{type: {_eq: "businessFigure"}}, {subType: {_in: $types}}]}) {
            affected_rows
        }
    }
`;


export const MarkDocumentAsDownloadedMutation = gql`
    mutation update_businessFigureLoadingStatus($id: Int!) {
        update_businessFigureLoadingStatus_by_pk(_set:{downloaded: true}, pk_columns: {id: $id}) {
            id
        }
    }
`;

export const AllStatisticsMutations = [
    RequestBusinessFigurePdfMutation,
    InsertBusinessFigureSubscriptions,
    DeleteBusinessFigureSubscription,
];

@Injectable({
    providedIn: 'root',
})
export class StatisticsMutations {
    constructor(
        private apollo: Apollo,
        private apiService: ApiService,
    ) {}

    requestBusinessFigurePdf(type: string, fromDate: string, toDate: string, producerId: number = null): Promise<void> {
        return new Promise(resolve => {
            this.apollo.mutate({
                mutation: RequestBusinessFigurePdfMutation,
                variables: {
                    type,
                    fromDate,
                    toDate,
                    producerId
                }
            }).subscribe({
                next: result => {
                    from([result])
                        .pipe(map(d => d?.data && d?.data['RequestBusinessFigurePdf'] && d?.data['RequestBusinessFigurePdf']['status']))
                        .subscribe((status: string) => {
                            resolve();
                            if (!status || status === 'ERROR' || !!result['errors']) {
                                void this.apiService.presentErrorToast(
                                    result['errors'],
                                    'Die Anfrage für das Dokument konnte leider nicht übermittelt werden.'
                                );
                            } else {
                            }
                        });
                },
                error: error => {
                    resolve();
                    void this.apiService.presentErrorToast(error, 'Die Anfrage für das Dokument konnte leider nicht übermittelt werden.');
                }
            });
        });
    }

    public insertBusinessFigureSubscriptions(types: BusinessFigurePdfTypeEnum[]) {
        const objects = types.map(type => ({
            type: 'businessFigure',
            subType: type as string
        }));
        this.apollo.mutate({
            mutation: InsertBusinessFigureSubscriptions,
            variables: {
                objects
            }
        }).subscribe({
            next: result => {
                from([result])
                    .pipe(map(d => d?.data && d?.data['insert_subscriptions'] && d?.data['insert_subscriptions']['affected_rows']))
                    .subscribe((affectedRows: number) => {
                        if (affectedRows > 0) {
                            // get the next upcoming 10th day of a month in the format dd.mm.YYYY
                            const date = new Date();  // Get the current date
                            const currentDay = date.getDate();  // Get the current day

                            // Check if it's already past the 10th of the month
                            if (currentDay >= 10) {
                                date.setMonth(date.getMonth() + 1);  // if so, move to the next month
                            }

                            // Set the day of the month to 10
                            date.setDate(10);
                            const nextTenthFormatted = date.toLocaleDateString();
                            void this.apiService.presentSuccessToast(`Die nächste Statistik wird Ihnen am ${nextTenthFormatted} zur Verfügung gestellt.`);
                        }
                    });
            },
        });
    }

    public deleteBusinessFigureSubscriptions(types: BusinessFigurePdfTypeEnum[]) {
        const objects = types.map(type => type as string);
        this.apollo.mutate({
            mutation: DeleteBusinessFigureSubscription,
            variables: {
                types: objects
            }
        }).subscribe({
            next: result => {
                from([result])
                    .pipe(map(d => d?.data && d?.data['delete_subscriptions'] && d?.data['delete_subscriptions']['affected_rows']))
                    .subscribe();
            },
        });
    }

    public markDocumentAsDownloaded(documentId: string | number) {
        this.apollo.mutate({
            mutation: MarkDocumentAsDownloadedMutation,
            variables: { id: documentId }
        }).subscribe({
            next: result => {
                from([result])
                    .pipe(map(d => d?.data && d?.data['update_businessFigureLoadingStatus_by_pk'] && d?.data['update_businessFigureLoadingStatus_by_pk']['affected_rows']))
                    .subscribe();
            },
        });
    }
}
