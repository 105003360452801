import { UpdateTimeframeEnum } from '../enums/update-timeframe.enum';

export const UpdatesTimeframeFilterConfig = [
    {
        id: UpdateTimeframeEnum.today,
        color: '',
        title: 'Heute',
        shortcode: 'Heute',
        sortOrder: 1,
        excludeFromFilter: true
    },
    {
        id: UpdateTimeframeEnum.one_day,
        color: '',
        title: 'vor einem Tag',
        shortcode: '1 Tag',
        sortOrder: 2,
        excludeFromFilter: true
    },
    {
        id: UpdateTimeframeEnum.three_days,
        color: '',
        title: 'vor drei Tagen',
        shortcode: '3 Tage',
        sortOrder: 3,
        excludeFromFilter: true
    },
    {
        id: UpdateTimeframeEnum.seven_days,
        color: '',
        title: 'vor sieben Tagen',
        shortcode: '7 Tage',
        sortOrder: 4,
        excludeFromFilter: true
    }
];
