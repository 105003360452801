import { inject, signal } from '@angular/core';
import { combineLatest, debounceTime, switchMap } from 'rxjs';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { DisplayLocation } from '../../../core/core.enums';
import { NewsPostQueries } from '../../../core/store/graphql/queries/news-post.graphql';
import { NewsPostInterface } from '../../../core/interfaces/news-post.interface';

export class NewsService {
    private newsPostQueries = inject(NewsPostQueries);

    private _limit = signal<number>(100);
    public limit = this._limit.asReadonly();

    private _displayLocation = signal<DisplayLocation>(DisplayLocation.news);
    public displayLocation = this._displayLocation.asReadonly();

    private _news$ = combineLatest([toObservable(this._limit), toObservable(this._displayLocation)])
        .pipe(debounceTime(200))
        .pipe(switchMap(([limit, displayLocation]) => this.newsPostQueries.getNewsPosts(displayLocation, limit)));
    public news = toSignal<NewsPostInterface[]>(this._news$, {initialValue: null});


    setLimit(limit: number) {
        this._limit.set(limit);
    }

    setDisplayLocation(location: DisplayLocation) {
        this._displayLocation.set(location);
    }
}
