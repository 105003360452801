<ion-content class="colorset-{{color}}">
    <ng-container *ngIf="!isLoading">
        <!-- No data found -->
        <div class="display-flex ion-justify-content-center ion-align-items-center no-items full-height" *ngIf="items && items.length === 0">
            <ng-container *ngTemplateOutlet="noResultsTmpl"></ng-container>
        </div>

        <!-- List of communication zone data -->
        <div *ngFor="let item of items" class="item display-flex list-item-{{item.id}}" [class.item-table]="viewType === viewTypeTable" [class.active]="item.id === activeId">
            <ion-checkbox [@bulkVisible]="isBulkActive ? 'visible' : 'hidden'" [checked]="item.id | isInArray:bulkSelectedItems" (click)="onBulkItemChange($event, item.id)" aria-label="Mehrfachauswahl"></ion-checkbox>
            <div (click)="onItemClick(item.id)" *ngIf="viewType !== viewTypeTable">
                <div class="display-flex ion-justify-content-between item-title">
                    <ng-container *ngTemplateOutlet="titleBeforeTmpl; context: { $implicit: item }"></ng-container>
                    <ion-text>
                        <p><ng-container *ngTemplateOutlet="titleTmpl ? titleTmpl : defaultTitleTmpl; context: { $implicit: item }"></ng-container></p>
                        <ng-container *ngTemplateOutlet="titleAfterTmpl; context: { $implicit: item }"></ng-container>

                        <ng-template #defaultTitleTmpl let-item>{{item.title}}</ng-template>
                    </ion-text>

                    <div>
                        <ng-container *ngTemplateOutlet="titleRightTmpl; context: { $implicit: item }"></ng-container>
                    </div>
                </div>
                <div class="item-common" *ngIf="contentCommonTmpl">
                    <ng-container *ngTemplateOutlet="contentCommonTmpl; context: { $implicit: item }"></ng-container>
                </div>
                <div class="item-additional" *ngIf="contentAdditionalTmpl">
                    <ng-container *ngTemplateOutlet="contentAdditionalTmpl; context: { $implicit: item }"></ng-container>
                </div>
            </div>

            <div class="display-flex ion-justify-content-between max-width-100" (click)="onItemClick(item.id)" *ngIf="viewType === viewTypeTable">
                <ion-text class="display-flex ion-align-self-center">
                    <ng-container *ngTemplateOutlet="titleBeforeTmpl; context: { $implicit: item }"></ng-container>
                    <p><ng-container *ngTemplateOutlet="titleTmpl ? titleTmpl : defaultTitleTmpl; context: { $implicit: item }"></ng-container></p>
                    <ng-container *ngTemplateOutlet="titleAfterTmpl; context: { $implicit: item }"></ng-container>

                    <ng-template #defaultTitleTmpl let-item>{{item.title}}</ng-template>
                </ion-text>
                <div class="item-common display-flex-column" *ngIf="contentCommonTmpl">
                    <ng-container *ngTemplateOutlet="contentCommonTmpl; context: { $implicit: item }"></ng-container>
                </div>
                <div class="display-flex ion-align-items-center" *ngIf="titleRightTmpl">
                    <ng-container *ngTemplateOutlet="titleRightTmpl; context: { $implicit: item }"></ng-container>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Loading -->
    <ng-container *ngIf="isLoading">
        <div *ngFor="let item of [1,2,3,4]" class="item skeleton">
            <ion-text>
                <ion-skeleton-text animated ></ion-skeleton-text>
            </ion-text>

            <div class="item-common">
                <span><ion-skeleton-text animated ></ion-skeleton-text></span>
                <span i18n><ion-skeleton-text animated ></ion-skeleton-text></span>
            </div>
            <div class="item-additional">
                <span i18n><ion-icon name="fitness-outline"></ion-icon><ion-skeleton-text animated ></ion-skeleton-text> &sdot; <ion-skeleton-text animated ></ion-skeleton-text></span>
                <span i18n><ion-skeleton-text animated ></ion-skeleton-text></span>
            </div>
        </div>
    </ng-container>
    <ion-infinite-scroll threshold="15%" (ionInfinite)="onLoadMore()" #infiniteScroll [hidden]="!isLoading && items && items.length === 0">
        <ion-infinite-scroll-content
                loadingSpinner="crescent"
                loadingText="Lade weitere Einträge ...">
        </ion-infinite-scroll-content>
    </ion-infinite-scroll>
</ion-content>
