import { OrderStatusFilter, OrderType } from '../enums/orders.enum';
import { DateRangeOptionCodes } from '../enums/date-range.enum';
import { FilterResultInterface } from '../interfaces/filter-result.interface';
import { OrderTypesWithAllConfig } from './order-types.config';
import { OrderStatusFilterConfig } from './order-status.config';
import { ExpiryDateRangeConfig, GetDateRangeConfig } from './date-range.config';
import { UpdateTimeframeEnum } from '../enums/update-timeframe.enum';

export const defaultOrderFilters = {
    search: '',
    status: OrderStatusFilter.ALL,
    deliveryDateOption: DateRangeOptionCodes.all,
    deliveryDateFrom: null,
    deliveryDateTo: null,
    deliveryDateSignal: null,
    expiryDateOption: DateRangeOptionCodes.all,
    expiryDateFrom: null,
    expiryDateTo: null,
    expiryDateSignal: null,
    recTimeOption: DateRangeOptionCodes.all,
    recTimeFrom: null,
    recTimeTo: null,
    recTimeSignal: null,
    producer: '',
    pzn: '',
    type: 'all',
    updatesOnly: false,
    updatesUntil: UpdateTimeframeEnum.today
};
export const defaultOrderWidgetFilters = {
    type: OrderType.DISPOSITION,
};

export const OrderFilterResultConfig : FilterResultInterface = {
    hasDateFilter: true,
    hasSearchModal: true,
    defaultFilters: defaultOrderFilters,
    items: [
        {
            id: 'recTime',
            label: 'Bestelldatum',
            isDate: true,
            isRecDate: true,
            dateRangeOption: GetDateRangeConfig()
        },
        {
            id: 'expiryDate',
            label: 'Ablaufdatum',
            isDate: true,
            dateRangeOption: ExpiryDateRangeConfig()
        },
        {
            id: 'type',
            label: 'Typ',
            translation: OrderTypesWithAllConfig
        },
        {
            id: 'status',
            label: 'Status',
            translation: OrderStatusFilterConfig,
            useCheckboxes: true
        },
        {
            id: 'producer',
            label: 'Hersteller',
            searchLabel: 'Hersteller',
            searchOnly: true
        },
        {
            id: 'pzn',
            label: 'PZN',
            searchLabel: 'PZN',
            searchOnly: true
        },
        {
            id: 'search',
            label: 'Suche',
            searchOnly: true
        }
]};
