import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { InformationQueries } from '../store/graphql/queries/information.graphql';
import { DataChangedStateVar } from '../store/locals/dataChangeState.var';
import {
    DataChangedStateInterface,
    DataGlobalChangedStateInterface,
    DataPharmacyChangedStateInterface,
    DataPharmacyUserChangedStateInterface,
    DataUserChangedStateInterface
} from '../interfaces/pharmacy-state.interface';
import { UtilService } from './util.service';
import { formatDateToDefaultDateTimeFormat } from '../formatting/date.formatting';
import { DataChangedForceStateVar } from '../store/locals/dataChangeForceState.var';
import { unsubscribe, unsubscribeAll } from '../core.utils';
import { environment } from '../../../environments/environment';
import { DataChangedCountKeys, DataChangedKeys as DCK } from '../enums/data-changed-keys.enum';
import { NotificationCountsVar } from '../store/locals/notificationCounts.var';
import { NotificationDataChangeInterface } from '../interfaces/notification.interface';

const updateDateImmediately = [
    DCK.archiveChanged,
    DCK.userSettingsChanged,
    DCK.settingsCalendarSanacorpEventsChanged,
    DCK.settingsSecurityChanged,
    DCK.settingsNotificationChanged,
    DCK.pharmacyStoreSettingsChanged,
    DCK.noteByUserChanged,
    DCK.notificationChanged,
    DCK.readNotificationChanged,
    DCK.documentSearchChanged,
    DCK.offerQuoteChanged,
    DCK.meamindChanged,
    DCK.surveyUserChanged,
    DCK.businessFigureLoadingStatusChanged,
    DCK.safetyDataSheetsChanged,
    DCK.formResultChanged,
];

@Injectable({
    providedIn: 'root',
})
export class DataChangedService {

    constructor(
        private http: HttpClient,
        private dataChangedVar: DataChangedStateVar,
        private informationQueries: InformationQueries,
        private utilService: UtilService,
        private dataChangedForceState: DataChangedForceStateVar,
        private notificationCountsVar: NotificationCountsVar
    ) {
        // wait 10 seconds
        this.setChangedData = this.utilService.debounce(this.setChangedData, 10000);
    }

    strapiDataChangedInterval;
    dataChangedPharmacySubscription: Subscription;
    dataChangedUserSubscription: Subscription;
    dataChangedPharmacyUserSubscription: Subscription;
    dataChangedGlobalSubscription: Subscription;
    cachedDate: DataChangedStateInterface = Object.values(DCK)
        .reduce((a, v) => ({ ...a, [v]: null}), {});
    cachedCounts: NotificationDataChangeInterface = Object.values(DataChangedCountKeys)
        .reduce((a, v) => ({ ...a, [v]: null}), {});
    forceUpdate: DataChangedStateInterface = Object.values(DCK)
        .reduce((a, v) => ({ ...a, [v]: null}), {});

    /**
     * Stops all subscriptions
     */
    clearAllSubscribers = () => {
        unsubscribeAll([
            this.dataChangedPharmacySubscription,
            this.dataChangedUserSubscription,
            this.dataChangedPharmacyUserSubscription,
            this.dataChangedGlobalSubscription
        ]);
        this.clearStrapiDataChangedPolling();
    };

    /**
     * call notification query
     */
    public updateDataChanged() {
        unsubscribe(this.dataChangedPharmacySubscription);
        this.dataChangedPharmacySubscription = this.informationQueries.getDataChangedPharmacy()
            .subscribe((dataChangedPharmacy: Array<DataPharmacyChangedStateInterface>) => {
                if(dataChangedPharmacy && dataChangedPharmacy.length) {
                    void this.processSubscriptionChange(dataChangedPharmacy[0]);
                    void this.processSubscriptionCountChange(dataChangedPharmacy[0]);
                }
        });

        unsubscribe(this.dataChangedUserSubscription);
        this.dataChangedUserSubscription = this.informationQueries.getDataChangedUser()
            .subscribe((dataChangedUser: Array<DataUserChangedStateInterface>) => {
                if(dataChangedUser && dataChangedUser.length) {
                    void this.processSubscriptionChange(dataChangedUser[0]);
                    void this.processSubscriptionCountChange(dataChangedUser[0]);
                }
            });

        unsubscribe(this.dataChangedPharmacyUserSubscription);
        this.dataChangedPharmacyUserSubscription = this.informationQueries.getDataChangedPharmacyUser()
            .subscribe((dataChangedPharmacyUser: Array<DataPharmacyUserChangedStateInterface>) => {
                if(dataChangedPharmacyUser && dataChangedPharmacyUser.length) {
                    void this.processSubscriptionChange(dataChangedPharmacyUser[0]);
                    void this.processSubscriptionCountChange(dataChangedPharmacyUser[0]);
                } else {
                    void this.processSubscriptionCountChange({notificationReadNotArchivedCount: 0});
                }
            });

        unsubscribe(this.dataChangedGlobalSubscription);
        this.dataChangedGlobalSubscription = this.informationQueries.getDataChangedGlobal()
            .subscribe((dataChangedGlobal: Array<DataGlobalChangedStateInterface>) => {
                if(dataChangedGlobal && dataChangedGlobal.length) {
                    void this.processSubscriptionChange(dataChangedGlobal[0]);
                }
            });

        this.initStrapiDataChangedPolling();
    }


    /**
     * Process the data from subscription endpoints
     * @param data DataChangedStateInterface
     */
    public async processSubscriptionCountChange (data: DataChangedStateInterface) {
        let changed = false;
        const countsArray = Object.keys(data).filter(key => Object.values(DataChangedCountKeys).includes(key as DataChangedCountKeys));
        countsArray.forEach(key => {
            if (this.cachedCounts[key] !== data[key] && data[key] !== null) {
                this.cachedCounts[key] = data[key];
                changed = true;
            }
        });
        if (changed) {
            this.notificationCountsVar.set(this.cachedCounts);
        }
    }

    /**
     * Process the data from subscription endpoints
     * @param data DataChangedStateInterface
     */
    public async processSubscriptionChange (data: DataChangedStateInterface) {
        const forceUpdate = await this.dataChangedForceState.getViaPromise();
        let updateInstantly = false;
        let changed = false;
        Object.keys(data).forEach(key => {
            if(key === '__typename') {
                return;
            }
            const timeChanged = data[key] ? formatDateToDefaultDateTimeFormat(data[key]) : null;
            if (this.cachedDate[key] === null) {
                this.cachedDate[key] = timeChanged;
            } else if (this.cachedDate[key] < timeChanged) {
                this.cachedDate[key] = timeChanged;
                changed = true;

                // Add Keys to force an immediate update of the cached data
                if (updateDateImmediately.includes(key as DCK) || !forceUpdate || forceUpdate[key] !== this.forceUpdate[key]) {
                    updateInstantly = true;
                }
            }
        });
        this.forceUpdate = forceUpdate;
        if (changed) {
            if (updateInstantly) {
                this.dataChangedVar.set(this.cachedDate);
            } else {
                this.setChangedData();
            }
        }
    }
    setChangedData = () => {
        this.dataChangedVar.set(this.cachedDate);
    }

    public initStrapiDataChangedPolling() {
        this.checkStrapiDataChanged();
        this.clearStrapiDataChangedPolling();
        this.strapiDataChangedInterval = setInterval(() => {
            this.checkStrapiDataChanged();
        }, environment.pollingInterval.strapiDataChanged);
    }

    public clearStrapiDataChangedPolling() {
        clearInterval(this.strapiDataChangedInterval);
    }

    private checkStrapiDataChanged() {
        try {
            this.http.get(environment.mediaS3Uri + 'strapiDataChanged.json?t=' + new Date().getTime())
                .subscribe({
                    next: strapiSettings => {
                        if (strapiSettings) {
                            delete strapiSettings['id'];
                            delete strapiSettings['createdAt'];
                            delete strapiSettings['updatedAt'];

                            void this.processSubscriptionChange(strapiSettings);
                        }
                    }
                });
        } catch (e) {
            // Do nothing
        }
    }
}
