import { Injectable } from '@angular/core';

type HasArrayPropertyOfType<T, U> = {
    [K in keyof T]-?: T[K] extends U[] ? K : never;
}[keyof T] extends never
    ? false
    : true;

export interface FlattenedInnerRow<T> {
    id: number;
    data: T;
}

export interface FlattenedRow<T, U> extends FlattenedInnerRow<T> {
    children: FlattenedInnerRow<U>[];
}


export class FlattenedRows<T, U> extends Array<FlattenedRow<T, U>> {

    private currentRow = 0;
    private currentChild = 0;
    private currentSelectedId: number;

    constructor(startId: number = 0) {
        super();
        this.currentSelectedId = startId;
    }

    /**
     * Navigates through a collection of rows and their children based on the provided parameters.
     * If the current child index becomes negative, it indicates navigating "up"
     * in the list, so the last element of the previous group will be selected.
     * If we're in the first group of the list, the last group will be considered
     * the previous one.
     *
     * Similarly, this also works this way the other way around. When exceeding the
     * last child of a group, the first child of the next group will be selected.
     * If we're in the last group of the list, the first one will be considered the
     * next one.
     *
     * @param upDown - The number of rows or children to move up or down. Positive to move down, negative to move up.
     * @param childrenOnly - Indicates whether the navigation should restrict to child elements only.
     * @param [wrapAround=true] - Determines whether the navigation should "wrap around" when reaching the beginning or end of the collection.
     * @return The currently selected row or child after navigation, or null if the collection is empty.
     */
    navigate(upDown: number,
             childrenOnly: boolean,
             wrapAround: boolean = true,
    ): FlattenedRow<T, U> | FlattenedInnerRow<U> | null {
        if (this.length === 0) {
            return null;
        }

        if (!childrenOnly) {
            if ((this.currentSelectedId + upDown) < this.getFirstId()) {
                if (!wrapAround) {
                    this.currentSelectedId = this.getFirstId();
                } else {
                    const diff = upDown - this.currentSelectedId;
                    const diff2 = upDown - diff;
                    this.currentSelectedId = this.getLastId() - (diff2 - 1);
                }
            } else if ((this.currentSelectedId + upDown) > this.getLastId()) {
                if (!wrapAround) {
                    this.currentSelectedId = this.getLastId();
                } else {
                    const diff = this.getLastId() - this.currentSelectedId;
                    const diff2 = upDown - diff;
                    this.currentSelectedId = this.getFirstId() + (diff2 - 1);
                }
            } else {
                this.currentSelectedId += upDown;
            }
            const newSelected = this.findById(this.currentSelectedId);
            this.updateCurrentRowAndChildById(newSelected?.id);
            return newSelected;

        }

        this.currentChild += upDown;

        while (true) {
            if (this.currentChild < 0) {
                this.currentRow -= 1;

                if (this.currentRow < 0) {
                    if (!wrapAround) {
                        // Clamp to the first group and first child
                        this.currentRow = 0;
                        this.currentChild = 0;
                        break;
                    }else {
                        this.currentRow = this.length - 1;
                        this.currentChild = this[this.currentRow].children.length - 1;
                    }
                } else {
                    this.currentChild = this[this.currentRow].children.length - 1;
                }
            } else if (this.currentChild >= this[this.currentRow].children.length) {
                this.currentRow += 1;

                if (this.currentRow >= this.length) {
                    if (!wrapAround) {
                        // Clamp to the last group and last child
                        this.currentRow = this.length - 1;
                        this.currentChild = this[this.currentRow].children.length - 1;
                        break;
                    } else {
                        this.currentRow = 0;
                        this.currentChild = 0;
                        break;
                    }

                } else {
                    this.currentChild = 0;
                }
            } else {
                break;
            }
        }
        return this[this.currentRow].children[this.currentChild];
    }

    getRowById(id: number): FlattenedRow<T, U> | null {
        return this.find(row => row.id === id);
    }

    findChildById(id: number): FlattenedInnerRow<U> | null {
        for (const row of this) {
            const foundChild = this._findChildInRow(row, id);
            if (foundChild) {
                return foundChild;
            }
        }
        return null;
    }

    findById(id: number): FlattenedRow<T, U> | FlattenedInnerRow<U> | null {
        return this.findChildById(id) || this.getRowById(id);
    }

    getCurrentChild(): FlattenedInnerRow<U> | null {
        return this[this.currentRow].children[this.currentChild];
    }

    getCurrentRow(): FlattenedRow<T, U> | null {
        return this[this.currentRow];
    }

    getPreviousRow(wrapAround: boolean = true): FlattenedRow<T, U> | null {
        let previousRowIndex = this.currentRow - 1;
        if (previousRowIndex < 0) {
            if (!wrapAround) {
                return null;
            }
            previousRowIndex = this.length - 1;
        }
        return this[previousRowIndex];
    }

    getNextRow(): FlattenedRow<T, U> | null {
        let nextRowIndex = this.currentRow + 1;
        if (nextRowIndex >= this.length) {
            nextRowIndex = 0;
        }
        return this[nextRowIndex];
    }

    getCurrentRowIndex(): number {
        return this.currentRow;
    }
    getNextRowIndex(): number {
        return this.currentRow + 1;
    }

    getCurrentChildIndex(): number {
        return this.currentChild;
    }

    getFirstChildOfFirstRow(): FlattenedInnerRow<U> | null {
        return this.getFirstChildOfRow(0);
    }

    getFirstChildOfRow(index: number): FlattenedInnerRow<U> | null {
        return this[index].children[0];
    }

    getFirstRow(): FlattenedRow<T, U> | null {
        return this[0];
    }

    getChildOfRow(index: number, childIndex: number): FlattenedInnerRow<U> | null {
        return this[index].children[childIndex];
    }

    updateCurrentRowAndChild(rowId: number, childId: number) : FlattenedInnerRow<U> | null {
        this.currentRow = rowId;
        this.currentChild = childId;
        return this.getCurrentChild();
    }

    updateCurrentRowAndChildById(rowId: number) : FlattenedRow<T, U> | FlattenedInnerRow<U> | null {
        const found = this.findById(rowId);
        if (!found) {
            return null;
        }
        this._updateCurrentRowAndChild(found);
        this.currentSelectedId = rowId;
        return found;
    }

    getRowOfChild(child: FlattenedInnerRow<U>) : FlattenedRow<T, U> | null {
        return this.find(row => row.children.includes(child));
    }

    getLastId(): number {
        const lastRow = this[this.length - 1];
        if (lastRow.children.length > 0) {
            return lastRow.children[lastRow.children.length - 1].id;
        }
        return lastRow.id;
    }
    getFirstId(): number {
        return this[0].id;
    }

    private _isChild(element: any): element is FlattenedInnerRow<any> {
        return true;
    }

    private _isOuterRow(element: any): element is FlattenedRow<any, any> {
        return true;
    }

    private _findChildInRow(row: FlattenedRow<T, U>, id: number): FlattenedInnerRow<U> | null {
        for (const child of row.children) {
            if (child.id === id) {
                return child;
            }
        }
        return null;
    }

    private _updateCurrentRowAndChild(element: FlattenedRow<T, U> | FlattenedInnerRow<U>) {
        if (this._isOuterRow(element)) {
            this.currentRow = this.indexOf(element);
            this.currentChild = 0;
        } else if (this._isChild(element)) {
            const group = this.getRowOfChild(element);
            this.currentRow = this.indexOf(group);
            this.currentChild = group.children.indexOf(element);
        }
    }
}

@Injectable({
    providedIn: 'root',
})
export class TableService {

    /**
     * Maps and transforms rows of a table starting from a given index.
     * The goal of this transformation is to create a nested structure of table rows that are indexed
     * consecutively, i.e. if the outer row has an index of 2 and has 3 children, they will continue with the indexes
     * 3, 4, and 5, and the next outer row will have an index of 6.
     *
     * @param rows - The input rows to be transformed.
     * @param [firstIndex=0] - The starting index for processing the rows.
     * @return The transformed rows in the form of a FlattenedRows object.
     */
    mapTableRows<T, U>(rows: T[], firstIndex: number = 0): FlattenedRows<T, U> {
        if (rows.length === 0) {
            return new FlattenedRows<T, U>(firstIndex);
        }
        const referenceRow = rows[0];
        if (!this._isRowT(referenceRow)) {
            throw new Error('Table rows must not be empty');
        }

        this._checkRows<T, U>(); // compile-time check

        const result: FlattenedRows<T, U> = new FlattenedRows<T, U>(firstIndex);
        let index = firstIndex;
        for (const row of rows) {
            const transformed = this._transformRow<T, U>(row, index);
            index = transformed.end;
            result.push(transformed.row);
        }
        return result;
    }

    private _checkRows<T, U>(): HasArrayPropertyOfType<T, U> {
        return null as any;
    }

    /**
     * Transforms a given row object by flattening its nested arrays into child rows and computes an ending index.
     *
     * @param row - The row object to be transformed.
     * @param start - The starting index for the transformation process.
     * @return An object containing the ending index after transformation and the transformed row structure.
     */
    private _transformRow<T, U>(row: T, start: number): {end: number, row: FlattenedRow<T, U>} {
        const children : FlattenedInnerRow<U>[] = [];
        let index = start + 1;
        for (const key in row) {
            if (Array.isArray(row[key])) {
                let innerRow: U[];
                try {
                    innerRow = row[key];
                } catch (e) {
                    continue;
                }
                let j = index;
                for (; j < index + innerRow.length; j++) {
                    children.push({
                        id: j,
                        data: innerRow[j - index],
                    } as FlattenedInnerRow<U>);
                }
                index = j;
            }
        }
        return {
            end: index,
            row: {
                id: start,
                data: row,
                children,
            } as FlattenedRow<T, U>,
        };
    }

    /**
     * Determines if the provided row is of type T.
     * This is a type guard function that helps the TypeScript compiler
     * infer the type of the row parameter when it returns true.
     *
     * @param row - The row to be evaluated, which can either be of type T or type E.
     * @return A boolean value indicating if the row is of type T.
     */
    private _isRowT<T, U>(row: T | U): row is T {
        return true;
    }

    /**
     * Determines if the given row is of type E.
     *
     * @param row The row to be checked; either type T or type E.
     * @return True if the row is of type E, otherwise false.
     */
    private _isRowU<T, U>(row: T | U): row is U {
        return true;
    }
}
