import {
    Component,
    OnInit,
    Input,
    Output,
    ContentChild,
    ElementRef,
    EventEmitter
} from '@angular/core';
import { ThemeColorEnum } from '../../enums/color.enum';


/**
 *
 * Usage:
 * <app-widget>
 *   <div header></div>
 *   <div content></div>
 *   <div footer></div>
 * </app-widget>
 */
@Component({
    selector: 'app-widget',
    templateUrl: './widget.component.html',
    styleUrls: ['./widget.component.scss'],
})
export class WidgetComponent implements OnInit {
    @Input() title = '';
    @Input() backgroundColor = ThemeColorEnum.PRIMARY_CONTRAST;
    @Input() textColor = ThemeColorEnum.BLACK;
    @Input() cardId = '';
    @Input() displayHeader = true;
    @Input() isStandalone = true;
    @Input() noBorderRadius = false;
    @Input() useSmallerPadding = false;
    @Input() noPadding = false;
    @Input() noPaddingHeader = false;
    @Input() customPaddingLeft = false;
    @Input() removeMarginTop = false;
    @Input() scrollableContent = false;
    @Input() scrollableOverflow = false;
    @Input() overflowVisible = false;
    @Input() hideBoxShadow = false;
    @Input() hasMinHeight = true;
    @Input() hasMinHeaderHeight = false;
    @Input() hasHeaderUnderline = false;
    @Input() ttk ='';
    @Input() iconName = '';
    @Input() iconColor = '';
    @Input() contentFullHeight = false;
    @Output() scrollTop = new EventEmitter<number>();
    @ContentChild('footer') footer: ElementRef;

    constructor() { }

    ngOnInit() {
    }


    onCardScroll(event){
        this.scrollTop.emit(event.target.scrollTop);
    }
}
