import { AfterViewInit, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputValidationFieldEnum } from '../../enums/input-validation.enum';
import { InputValidationInterface } from '../../interfaces/input-validation.interface';

@Component({
    selector: 'app-dropdown-input',
    templateUrl: './dropdown-input.component.html',
    styleUrls: ['./dropdown-input.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: forwardRef(() => DropdownInputComponent)
    }]
})
export class DropdownInputComponent implements AfterViewInit, ControlValueAccessor {
    @ViewChild('input') input;

    @Input({required: true}) label = '';
    @Input({required: true}) formControlName = '';
    @Input({required: true}) validationFieldEnum: InputValidationFieldEnum = InputValidationFieldEnum.title;
    @Input({required: true}) validators: InputValidationInterface[];
    @Input({required: true}) items: Array<string> = [];

    @Input() placeholder = '';
    @Input() isInvalid = false;
    @Input() isRequired = false;
    @Input() setFocus = false;
    @Input() labelClass = '';
    @Input() inputClass = '';
    @Input() tooltipInvalidValue : {title: string, list: string[]} ;

    showDropdown = false;

    // Keep this, otherwise elements are not selectable
    onChange = (value: string) => {};

    ngAfterViewInit() {
        if(this.setFocus) {
            // Timeout necessary because ion-life-cycle is working here
            setTimeout(() => {
                this.input.setFocus();
            },300);
        }
    }

    /**
     * User has clicked outside the dropdown input
     */
    onClickOutside() {
        this.showDropdown = false;
    }

    /**
     * User adds a new contact
     * @param event - Click event
     */
    onInputChange(event: Event) {
        this.onChange(event.target['value']);
    }

    /**
     * User clicked on dropdown item
     * @param value - Value of the dropdown item
     */
    onItemClick(value: string) {
        this.showDropdown = false;
        this.input.value = value;
        this.onChange(value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}
    writeValue(obj: any): void {}
}
