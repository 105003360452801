import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';

import {
    BusinessFigureLoadingInterface,
    StatisticsInterface,
    StatisticsPerDayInterface
} from '../../../core.interfaces';

import {
    AuthStorageKeyEnum,
    FetchPolicyKeys as FPK,
    DataChangedKeys as DCK,
    QueryFetchPolicy,
    StatisticTypeEnum,
    BusinessFigureDuettEnum
} from '../../../core.enums';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { QueryWrapper } from '../query.wrapper';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { BusinessFigureDuettInterface } from '../../../interfaces/business-figure-duett.interface';
import { convertFromLocalTimeToUTC, todayMinus } from '../../../formatting/date.formatting';

export const GetStatistics = (queryName) => gql`
    query ${queryName}($apiUser: String!, $limit: Int) {
        pharmacyStore: pharmacyStoreBusinessFigures(where: {apiUser: {_eq: $apiUser}}, limit: 1) {
            businessFigures(limit: $limit) {
                id
                name
                customValue
                updated_at
                yearMonth
            }
        }
    }
`;
export const GetStatisticsFiltered = (queryName) => gql`
    query ${queryName}($apiUser: String!, $limit: Int, $yearMonth: Int!, $types: [String!]) {
        pharmacyStore: pharmacyStoreBusinessFigures(where: {apiUser: {_eq: $apiUser}}, limit: 1) {
            businessFigures(limit: $limit, where: {yearMonth: {_gte: $yearMonth}, name: {_in: $types}})  {
                id
                name
                customValue
                updated_at
                yearMonth
            }
        }
    }
`;

export const GetStatisticsPerDay = gql`
    query BusinessFiguresPerDay($type: String!, $month: Int!, $year: Int!) {
        BusinessFiguresPerDay(type: $type, month: $month, year: $year) {
            day
            value
        }
    }
`;

export const GetBusinessFigureLoadingStatus = (queryName) => gql`
    query ${queryName}($where: businessFigureLoadingStatus_bool_exp!) {
        businessFigureLoadingStatus(where: $where, order_by: {id: desc}, limit: 100) {
            id
            isFinished
            downloaded
            filename
            toDate
            fromDate
            created_at
            updated_at
            producer {
                id
                producerName
            }
        }
    }
`;

export const GetBusinessFiguresLoadingStatus = (queryName) => gql`
    query ${queryName} {
        businessFigureLoadingStatus(order_by: {id: desc}, limit: 100) {
            id
            isFinished
            downloaded
            filename
            type
            toDate
            fromDate
            created_at
            updated_at
            producer {
                id
                producerName
            }
        }
    }
`;
export const GetAvailableBusinessFigures = (queryName) => gql`
    query ${queryName}($createdAt: timestamptz!) {
        businessFigureLoadingStatus_aggregate(where: {downloaded: {_eq:false}, isFinished: {_eq: true}, created_at: {_gte: $createdAt}}) {
            aggregate {
                count
            }
        }
    }
`;

export const GetBusinessFigureDuett = (queryName) => gql`
    query ${queryName}($args: businessFigureDuettFormatted_args!) {
        businessFigureDuettFormatted(args: $args, limit: 10) {
            average
            name
            sum
            yearMonth
        }
    }
`;

export const GetBusinessFigureSubscriptions = (queryName) => gql`
    query ${queryName}($limit: Int!) {
        subscriptions(where: {type: {_eq: "businessFigure"}}, limit: $limit) {
            userId
            pharmacyStoreId
            subType
        }
    }`



export const AllBusinessFigureQueries = [
    GetStatistics('test'),
    GetStatisticsFiltered('test'),
    GetStatisticsPerDay,
    GetBusinessFigureLoadingStatus('test'),
    GetBusinessFigureSubscriptions('test'),
    GetBusinessFigureDuett('test'),
    GetAvailableBusinessFigures('test')
];

@Injectable()
export class BusinessFigureQueries extends QueryWrapper {
    fetchPolicies = {
        [FPK.getStatistics]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getStatisticsFiltered]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getBusinessFigureLoadingStatus]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getBusinessFiguresLoadingStatus]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getBusinessFigureSubscriptions]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getBusinessFigureDuett]: QueryFetchPolicy.NETWORK_ONLY,
    };

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar
    ) {
        super(apollo, dataChangedVar, {
            [DCK.businessFigureChanged]: [
                FPK.getStatistics,
                FPK.getStatisticsFiltered
            ],
            [DCK.businessFigureLoadingStatusChanged]: [
                FPK.getBusinessFigureLoadingStatus,
                FPK.getBusinessFiguresLoadingStatus
            ],
            [DCK.businessFigureSubscriptionsChanged]: [
                FPK.getBusinessFigureSubscriptions
            ],
            [DCK.businessFigureDuettChanged]: [
                FPK.getBusinessFigureDuett
            ],

        });
    }

    /**
     * Return statistics of the pharmacy
     */
    public getStatistics(filters: {
        types: StatisticTypeEnum[],
        year: number
    } = null): Observable<StatisticsInterface[]> {
        let fetchPolicyKey = FPK.getStatistics;
        if(filters) {
            fetchPolicyKey = FPK.getStatisticsFiltered;
            return this.apollo.watchQuery({
                query: GetStatisticsFiltered(fetchPolicyKey),
                variables: {
                    limit: GraphQLLimits.businessFigure,
                    yearMonth: filters.year * 100, // Get begin of a year 20xx00
                    types: filters.types,
                    apiUser: localStorage.getItem(AuthStorageKeyEnum.activePharmacy)
                },
                fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
            }).valueChanges
                .pipe(map(d => d?.data && d?.data['pharmacyStore'] && d?.data['pharmacyStore'][0] &&
                    d?.data['pharmacyStore'][0]['businessFigures']))
                .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<StatisticsInterface[]>;
        }
        return this.apollo.watchQuery({
            query: GetStatistics(fetchPolicyKey),
            variables: {limit: GraphQLLimits.businessFigure, apiUser: localStorage.getItem(AuthStorageKeyEnum.activePharmacy)},
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['pharmacyStore'] && d?.data['pharmacyStore'][0] &&
                d?.data['pharmacyStore'][0]['businessFigures']))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<StatisticsInterface[]>;
    }

    /**
     * Return statistics per day of the pharmacy
     */
    public getStatisticsPerDay(type, month, year): Observable<StatisticsPerDayInterface[]> {
        return this.apollo.watchQuery({
            query: GetStatisticsPerDay,
            variables: {type, month, year},
            fetchPolicy: QueryFetchPolicy.NETWORK_ONLY
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['BusinessFiguresPerDay'])) as Observable<StatisticsPerDayInterface[]>;
    }

    public getBusinessFigureLoading(type: string): Observable<Array<BusinessFigureLoadingInterface>> {
        const fetchPolicyKey = FPK.getBusinessFigureLoadingStatus;
        const where = {
            _and: [
                { type: {_eq: type}}
            ]
        };
        const orderBy = {
            created_at: 'desc'
        };
        return this.apollo.watchQuery({
            query: GetBusinessFigureLoadingStatus(fetchPolicyKey),
            variables: {where, order_by: orderBy},
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(
                map(d => d?.data && d.data['businessFigureLoadingStatus']),
                map(d =>
                    (d as BusinessFigureLoadingInterface[]).filter(
                         bF => new Date(bF.created_at) >= new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 14)
                    )
                ),
                map(d => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;}
            ));
    }

    public getBusinessFiguresLoading(): Observable<Array<BusinessFigureLoadingInterface>> {
        const fetchPolicyKey = FPK.getBusinessFiguresLoadingStatus;
        const orderBy = {
            created_at: 'desc'
        };
        return this.apollo.watchQuery({
            query: GetBusinessFiguresLoadingStatus(fetchPolicyKey),
            variables: {order_by: orderBy},
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(
                map(d => d?.data && d.data['businessFigureLoadingStatus']),
                map(d =>
                    (d as BusinessFigureLoadingInterface[]).filter(
                         bF => new Date(bF.created_at) >= new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 14)
                    )
                ),
                map(d => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;}
            ));
    }

    public getBusinessFigureSubscriptions(): Observable<any[]> {
        const fetchPolicyKey = FPK.getBusinessFigureSubscriptions;
        return this.apollo.watchQuery({
                    query: GetBusinessFigureSubscriptions(fetchPolicyKey),
                    fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
                    variables: {limit: GraphQLLimits.defaultLimit},
                })
                    .valueChanges
                    .pipe(
                        map(d => d?.data && d?.data['subscriptions']),
                        map(d => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})
                    );
    }

    /**
     * Get business figure duett (mea chat and mea shop)
     */
    public getBusinessFigureDuett(types: BusinessFigureDuettEnum[], startDate: string): Observable<BusinessFigureDuettInterface[]> {
        const nameList = types.map(t => BusinessFigureDuettEnum[t]).join(',');
        const fetchPolicyKey = FPK.getBusinessFigureDuett;

        return this.apollo.watchQuery({
            query: GetBusinessFigureDuett(fetchPolicyKey),
            variables: {
                args: {
                    name_list: `{${nameList}}`,
                    start_date: startDate
                }
            },
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
        })
            .valueChanges
            .pipe(
                map(d => d?.data && d.data['businessFigureDuettFormatted']),
                map(d => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})
            );
    }

    /**
     * Get business figure duett (mea chat and mea shop)
     */
    public getAvailableBusinessFiguresAmount(): Observable<number> {
        const fetchPolicyKey = FPK.getBusinessFiguresLoadingStatus;

        return this.apollo.watchQuery({
            query: GetAvailableBusinessFigures(fetchPolicyKey),
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
            variables: {
                // Only show the amount of available business figures that were created in the last 14 days
                // Taken from getBusinessFiguresLoading() in this file
                createdAt: convertFromLocalTimeToUTC(todayMinus(14))
            }
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['businessFigureLoadingStatus_aggregate']
                && d?.data['businessFigureLoadingStatus_aggregate']['aggregate']
                && d?.data['businessFigureLoadingStatus_aggregate']['aggregate']['count']),
                map(d => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})
            );
    }
}
