import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { from, map } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { DataChangedKeys as DCK } from '../../../core.enums';
import { NotesInterface } from '../../../interfaces/notes.interface';
import { DataChangedForceStateVar } from '../../locals/dataChangeForceState.var';

export const UpsertCommunicationNotesMutation = gql`
    mutation insertCommunicationNoteMutation($notes: [note_insert_input!]!) {
        insert_note(
            objects: $notes,
            on_conflict: {
                constraint: note_communicationId_pharmacyStoreId_key,
                update_columns: [note]
            }
        ) {
            affected_rows
        }
    }
`;

export const UpsertOrderNotesMutation = gql`
    mutation insertOrderNoteMutation($notes: [note_insert_input!]!) {
        insert_note(
            objects: $notes,
            on_conflict: {
                constraint: note_orderId_pharmacyStoreId_key,
                update_columns: [note]
            }
        ) {
            affected_rows
        }
    }
`;

export const UpdateNotesMutation = gql`
    mutation updateNoteMutation($note: String, $id: Int!) {
        update_note_by_pk(
            _set: {note: $note},
            pk_columns: {id: $id}
        ) {
            id
        }
    }
`;

export const UpdateNoteByUserMutation = gql`
    mutation updateNoteByUserMutation($title: String, $note: String) {
        insert_noteByUser_one(
            object: {title: $title, note: $note},
            on_conflict: {
                constraint: noteByUser_userId_key,
                update_columns: [title, note]
            }
        ) {
            title
            note
        }
    }
`;

export const DeleteOrderNoteMutation = gql`
    mutation deleteOrderNoteMutation($id: Int!) {
        delete_note(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;


export const DeleteNoteMutation = gql`
    mutation deleteNoteMutation($id: Int!) {
        delete_note(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;
export const AllNotesMutations = [
    UpsertCommunicationNotesMutation,
    UpsertOrderNotesMutation,
    UpdateNotesMutation,
    UpdateNoteByUserMutation,
    DeleteOrderNoteMutation,
    DeleteNoteMutation
];

@Injectable()
export class NotesMutations {

    constructor(
        private apollo: Apollo,
        private apiService: ApiService,
        private dataChangedForceState: DataChangedForceStateVar
    ) {}

    /**
     * trim values
     * @param data - Data to trim (string or null)
     * @return string|null
     */
    formatInputs = (data) => {
        if (typeof data === 'string') {
            return data.trim().length > 0 ? data.trim() : null;
        }
        return data;
    }

    upsertCommunicationNotes(notes: NotesInterface, id) {
        void this.dataChangedForceState.setForceState({[DCK.noteChanged]: null});
        Object.keys(notes).forEach(key => {
            notes[key] = this.formatInputs(notes[key]);
        });
        let mutation = UpsertCommunicationNotesMutation;
        let variables: any = {notes: [notes]};
        if (id) {
            mutation = UpdateNotesMutation;
            variables = {note: notes.note, id};
        }
        this.apollo.mutate({
            mutation,
            variables
        }).subscribe({
            next: () => this.apiService.presentSuccessToast('Notiz wurde erfolgreich gespeichert!'),
            error: error => this.apiService.presentErrorToast(error, 'Die Notiz konnte nicht gespeichert werden.')
        });
    }

    upsertOrderNotes(orderNotes: NotesInterface, id) {
        void this.dataChangedForceState.setForceState({[DCK.noteChanged]: null});
        Object.keys(orderNotes).forEach(key => {
            orderNotes[key] = this.formatInputs(orderNotes[key]);
        });
        let mutation = UpsertOrderNotesMutation;
        let variables: any = {notes: [orderNotes]};
        if (id) {
            mutation = UpdateNotesMutation;
            variables = {note: orderNotes.note, id};
        }
        this.apollo.mutate({
            mutation,
            variables
        }).subscribe({
            next: () => this.apiService.presentSuccessToast('Notiz wurde erfolgreich gespeichert!'),
            error: error => this.apiService.presentErrorToast(error, 'Die Notiz konnte nicht gespeichert werden.')
        });
    }

    upsertNoteByUser(title: string, note: string) {
        this.apollo.mutate({
            mutation: UpdateNoteByUserMutation,
            variables: {
                title,
                note
            }
        }).subscribe({
            next: () => {/* Do nothing */},
            error: error => this.apiService.presentErrorToast(error, 'Die Notiz konnte nicht gespeichert werden.')
        });

    }

    deleteNote(id: number) {
        void this.dataChangedForceState.setForceState({[DCK.noteChanged]: null});
        this.apollo.mutate({
            mutation: DeleteNoteMutation,
            variables: {id}
        }).subscribe({
            next: () => this.apiService.presentSuccessToast('Notiz wurde gelöscht!'),
            error: error => this.apiService.presentErrorToast(error, 'Die Notiz konnte nicht gelöscht werden.')
        });
    }

    deleteOrderNote(id: number) {
        void this.dataChangedForceState.setForceState({[DCK.noteChanged]: null});
        this.apollo.mutate({
            mutation: DeleteOrderNoteMutation,
            variables: {id}
        }).subscribe({
            next: result => {
                from([result])
                  .pipe(map(d => d?.data && d?.data['delete_note'] && d?.data['delete_note']['status']))
                  .subscribe((status: string) => {
                      if (!status || status === 'ERROR' || !!result['errors']) {
                          void this.apiService.presentErrorToast(null, 'Die Notiz konnte nicht gelöscht werden.');
                      } else {
                          void this.apiService.presentSuccessToast('Notiz wurde gelöscht!');
                      }
                  }).unsubscribe();
            },
            error: error => this.apiService.presentErrorToast(error, 'Die Notiz konnte nicht gelöscht werden.')
        });
    }

}
