export enum Color {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    SUCCESS = 'success',
    WARNING = 'warning',
    DANGER = 'danger',
    LIGHT = 'light',
    MEDIUM = 'medium',
    DARK = 'dark',
    TRANSPARENT = 'transparent'
}

export enum ThemeColorEnum {
    // always use rgb values so that the css code can apply alpha values
    PRIMARY = 'var(--ion-color-primary-rgb)',
    PRIMARY_SHADE = 'var(--ion-color-primary-shade-rgb)',
    PRIMARY_CONTRAST = 'var(--ion-color-primary-contrast-rgb)',
    LIGHT_SHADE = 'var(--ion-color-light-shade-rgb)',
    BLACK = 'var(--ion-color-black-rgb)',
    MEA = 'var(--mea-dark-rgb)',
    BLUE = 'var(--ion-color-blue-rgb)',
    BLUE_SHADE = 'var(--ion-color-blue-shade-rgb)'
}

