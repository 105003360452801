import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ModalService } from './modal.service';
import { ToastService } from './toast.service';
import { AppointmentInterface } from '../interfaces/appointments.interface';
import { AppointmentQueries } from '../store/graphql/queries/appointment.graphql';
import { AppointmentMutations } from '../store/graphql/mutations/appointments.graphql';
import { KeyCodesEnum } from '../enums/key-codes.enum';

@Injectable({
    providedIn: 'root',
})
export class AppointmentService {
    appointment: AppointmentInterface;

    constructor(
        private alertController: AlertController,
        private appointmentQueries: AppointmentQueries,
        private appointmentMutations: AppointmentMutations,
        private modalService: ModalService,
        public toastService: ToastService
    ) { }

    getAppointment(
        appointmentId: number,
        lockedBySanacorp: boolean = true) : Observable<AppointmentInterface> {
        if (lockedBySanacorp) {
            return this.appointmentQueries.getAppointmentsBySanacorpById(appointmentId);
        } else {
            return this.appointmentQueries.getAppointmentById(appointmentId);
        }
    }

    async deleteAppointment(appointment, closeModal = false) {
        this.appointment = appointment;
        const alert = await this.alertController.create({
            header: 'Termin löschen',
            message: 'Möchten Sie den Termin wirklich löschen?',
            buttons: [
                {
                    text: 'Abbrechen',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {

                    }
                }, {
                    text: 'Löschen',
                    handler: () => {
                        this.doDeleteAppointment(closeModal);
                    }
                }
            ]
        });

        alert.addEventListener('keyup', async (event) => {
            if(event.key === KeyCodesEnum.ENTER && event.currentTarget instanceof HTMLElement) {
                event.currentTarget.getElementsByTagName('button')[1].click();
            }

            if(event.key === KeyCodesEnum.ESCAPE) {
                void alert.dismiss();
            }
        });
        await alert.present();
    }

    private async doDeleteAppointment(closeModal) {
        if (this.appointment.lockedBySanacorp) {
            await this.toastService.presentError('Gesperrte Termine dürfen nicht gelöscht werden.');
            return;
        }
        await this.appointmentMutations.deleteAppointment(this.appointment.id);
        if (closeModal) {
            await this.modalService.dismiss();
        }
    }
}
