import { Component, EventEmitter, OnInit, Input, Output, HostListener, ViewChild } from '@angular/core';
import { ButtonInputInterface } from '../../interfaces/button-action.interface';

@Component({
    selector: 'app-segment-button',
    templateUrl: './segment-button.component.html',
    styleUrls: ['./segment-button.component.scss']
})
export class SegmentButtonComponent implements OnInit {
    @ViewChild('segmentPopover') segmentPopover;

    @Input() items: Array<{
        id: string;
        label: string;
    }> = [];

    @Input() value = '';

    @Input() breakpoint = 1280;
    @Input() flatStyle = false;

    @Output() change = new EventEmitter<string>();

    _showPopover = false;
    _popoverButtons: Array<ButtonInputInterface> = [];
    _isPopoverOpen = false;

    @HostListener('window:resize')
    onResize() {
        this._checkSize();
    }

    ngOnInit() {
        this._checkSize();
        this._popoverButtons = this.items.map(item => ({code: item.id, label: item.label}));
    }

    /**
     * Handle value change by user
     *
     * @param event - Change event
     */
    _onChange(event) {
        this.change.emit(event.detail.value);
    }

    /**
     * User clicked on popover button
     *
     * @param event - Click event
     */
    _onPopoverClick(event) {
        this.segmentPopover.event = event;
        this._isPopoverOpen = true;
    }

    /**
     * User clicked a single button in popover
     *
     * @param event - Click event
     */
    _onPopoverButtonClick(event) {
        this._isPopoverOpen = false;
        this.change.emit(event.code);
    }

    /**
     * Check if the segment button or the popover should be visible
     */
    _checkSize() {
        const {innerWidth} = window;
        const showPopover = innerWidth < this.breakpoint;
        if(this._showPopover !== showPopover) {
            this._showPopover = showPopover;
        }
    }
}
