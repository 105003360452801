import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { LetDirective } from '@ngrx/component';
import { DropdownModule } from 'primeng/dropdown';

import { CoreModule } from '../../../core/core.module';
import { IconNumberBoxesComponent } from './icon-number-boxes/icon-number-boxes.component';
import { NumberBoxesComponent } from './number-boxes/number-boxes.component';
import { StatisticsCompareBarChartComponent } from './statistics-compare-bar-chart/statistics-compare-bar-chart.component';
import { PinLockComponent } from '../../../core/components/pin-lock/pin-lock.component';
import { PinUnlockComponent } from '../../../core/components/pin-unlock/pin-unlock.component';
import { SalesPopoverComponent } from './sales-popover/sales-popover.component';
import { CoopPopoverComponent } from './coop-popover/coop-popover.component';
import { SimpleDonutChartComponent } from './simple-donut-chart/simple-donut-chart.component';
import { StatisticTableComponent } from './statistic-table/statistic-table.component';
import { PackagePopoverComponent } from './package-popover/package-popover.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CoreModule,
        IonicModule,
        TableModule,
        CalendarModule,
        LetDirective,
        DropdownModule,
    ],
    declarations: [
        NumberBoxesComponent,
        IconNumberBoxesComponent,
        StatisticsCompareBarChartComponent,
        PinLockComponent,
        PinUnlockComponent,
        PackagePopoverComponent,
        SalesPopoverComponent,
        CoopPopoverComponent,
        SimpleDonutChartComponent,
        StatisticTableComponent
    ],
    exports: [
        NumberBoxesComponent,
        IconNumberBoxesComponent,
        StatisticsCompareBarChartComponent,
        PinLockComponent,
        PinUnlockComponent,
        PackagePopoverComponent,
        SalesPopoverComponent,
        CoopPopoverComponent,
        SimpleDonutChartComponent,
        StatisticTableComponent
    ]
})
export class StatisticsComponentsModule {
}
