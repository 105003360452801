import { Injectable, inject } from '@angular/core';
import { map, take } from 'rxjs';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { NavigationRoutesEnum } from '../navigation/navigation.routes';
import { FullMeaRoutesEnum } from '../core/enums/mea-routes.enum';
import { PharmacyStoreInterface } from '../core/interfaces/customer.interface';
import { PharmacyStoreStateVar } from '../core/store/locals/pharmacyStoreState.var';
import { MeaDynamicPageMembershipTypes } from '../core/config/mea.config';
import { PharmacyStoreService } from '../core/services/pharmacyStore.service';

@Injectable({
    providedIn: 'root',
})
export class MeaAccessGuard {
    private pharmacyStoreStateVar = inject(PharmacyStoreStateVar);

    constructor(private router: Router, private pharmacyStoreService: PharmacyStoreService) {
    }

    private activePharmacyStore = this.pharmacyStoreStateVar.activePharmacyStoreState;
    private isMea = this.pharmacyStoreStateVar.isMea;
    activePharmacyStore$ = this.pharmacyStoreStateVar.activePharmacyStoreState$;

    /**
     * Activates the route only if the user has access rights to this module
     */
    canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) {
        return new Promise((resolve) => {
            this.activePharmacyStore$.pipe(
                take(2),
                map(activePharmacy => {
                        if (activePharmacy === undefined) {
                            // this is necessary for reloading a mea page, as the toolbar component is not loaded yet
                            this.pharmacyStoreService.updateDataChanged(false). then(() => {
                                this.pharmacyStoreService.clearAllSubscribers();
                            });
                            return;
                        }
                        switch (routerState.url) {
                            case FullMeaRoutesEnum.shop:
                                // tslint:disable-next-line:max-line-length
                                return this.checkPagePermissions(resolve, (actPharmacy: PharmacyStoreInterface) => !!actPharmacy?.shopAvailable);
                            case FullMeaRoutesEnum.dynamicContent:
                                return this.checkPagePermissions(resolve, (actPharmacy: PharmacyStoreInterface) =>
                                    MeaDynamicPageMembershipTypes.includes(actPharmacy.membershipType)
                                );
                            default:
                                if (this.isMea()) {
                                    resolve(true);
                                    return;
                                } else {
                                    void this.router.navigateByUrl(NavigationRoutesEnum.dashboard);
                                    resolve(false);
                                }
                        }
                    }
                )).subscribe();
        });
    }

    /**
     * Check if the user has access to the page
     * @private
     */
    private checkPagePermissions(resolve: (value: boolean) => void, checkPermission: (activePharmacy: PharmacyStoreInterface) => boolean): void {
        const isAllowed = checkPermission(this.activePharmacyStore());
        if (!isAllowed) {
            void this.router.navigateByUrl(FullMeaRoutesEnum.offers);
        }
        resolve(isAllowed);
    }
}
