import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MarkdownModule } from 'ngx-markdown';
import { TableModule } from 'primeng/table';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { register } from 'swiper/element/bundle';

import { CoreModule } from '../../../core/core.module';
import { NewsCardComponent } from './news-card/news-card.component';
import { NewsComponentsModule } from '../components/news.components.module';
import { NewsWidgetComponent } from './news-widget/news-widget.component';


// Register swiper components
register();

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MarkdownModule,
        ReactiveFormsModule,
        CoreModule,
        TableModule,
        IonicModule,
        ClipboardModule,
        NgxBarcode6Module,
        CoreModule,
        NewsComponentsModule
    ],
    declarations: [
        NewsCardComponent,
        NewsWidgetComponent
    ],
    exports: [
        NewsCardComponent,
        NewsWidgetComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA] // Required for Swiper elements
})
export class NewsWidgetsModule {
}
