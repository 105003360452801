import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MarkdownModule } from 'ngx-markdown';

import { CoreModule } from '../../../core/core.module';
import { SeminarAcademyComponent } from './seminar-academy/seminar-academy.component';
import { EducationDetailsComponent } from './education-details/education-details.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        IonicModule,
        MarkdownModule
    ],
    declarations: [
        EducationDetailsComponent,
        SeminarAcademyComponent,
    ],
    exports: [
        EducationDetailsComponent,
        SeminarAcademyComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SeminarsWidgetsModule {
    constructor() {
    }
}
