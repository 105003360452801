import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Moment } from 'moment';
import * as dayjs from 'dayjs';

import {
    compareDates,
    convertFromLocalTimeToUTC,
    formatDateTimeToMoment,
    formatDateTimeToTime,
    formatMomentDateToDefaultDateTimeFormat,
    now,
    startOfWithUTC,
    today
} from '../../../../core/core.formatting';

import { AppointmentDescriptionValidator, AppointmentTitleValidator } from '../../../../core/core.validators';
import { AppointmentDateRangeConfig, TOOLTIPS } from '../../../../core/core.config';
import { CompareDateEnum, DateRangeOptionCodes, matomoIdsEnum } from '../../../../core/core.enums';
import { AppointmentInterface, DateRangeInterface, ProfileSettingInterface, } from '../../../../core/core.interfaces';
import { ToastService } from '../../../../core/services/toast.service';
import { AppointmentService } from '../../../../core/services/appointment.service';
import { InputValidationService, ModalService } from '../../../../core/core.services';
import { ModalClassEnum } from '../../../../core/enums/modal-class.enum';
import { AppointmentMutations } from '../../../../core/store/graphql/mutations/appointments.graphql';
import { unsubscribe, unsubscribeAll } from '../../../../core/core.utils';
import { FormQueries } from '../../../../core/store/graphql/queries/form.graphql';

@Component({
    selector: 'app-appointment-form',
    templateUrl: './appointment-form.component.html',
    styleUrls: ['./appointment-form.component.scss'],
})
export class AppointmentFormComponent implements OnInit {
    static modalClass = ModalClassEnum.medium;
    @Input() selectedDate: Moment;
    @Input() appointmentId: number;
    @Input() isSanacorp: boolean;
    @ViewChild('firstField') firstField: any;

    // Injects
    private formQueries = inject(FormQueries);

    matomoId = matomoIdsEnum.calendarAppointment;

    tooltips = TOOLTIPS;
    profileSettings: Observable<ProfileSettingInterface>;
    notificationReminderConfig: Array<{id: number, title: string}> = [
        {id:  null, title: 'keine'},
        {id: 1440, title: '1 Tag'},
        {id: 4320, title: '3 Tage'},
        {id: 7200, title: '5 Tage'}
    ];
    dataLoaded = false;
    appointment: AppointmentInterface;

    selectedFromDate: Moment;
    selectedToDate: Moment;
    selectedFromTime = dayjs().startOf('hour').format('HH:mm');
    selectedToTime = dayjs().startOf('hour').add(1, 'hours').format('HH:mm');
    selectedReminder = this.notificationReminderConfig[0];

    dateRangeOption: Array<DateRangeInterface> = AppointmentDateRangeConfig();
    defaultDateRangeOption: DateRangeOptionCodes = DateRangeOptionCodes.individual;
    defaultDate = '';
    lockedWhileSaving = false;
    isAlreadySubmitted = false;
    isFormLoading = true;
    openModal = false;

    appointmentFormGroup: FormGroup;
    appointmentSubscription: Subscription;
    formResultSubscription: Subscription;

    constructor(
        public formBuilder: FormBuilder,
        private appointmentService: AppointmentService,
        private appointmentMutations: AppointmentMutations,
        private modalService: ModalService,
        public toastService: ToastService
    ) { }

    ngOnInit() {
        this.appointment = null;
        unsubscribe(this.appointmentSubscription);

        if (this.appointmentId) {
            this.isFormLoading = true;
            this.appointmentSubscription = this.appointmentService.getAppointment(this.appointmentId, this.isSanacorp)
                .subscribe((appointment: AppointmentInterface) => {
                    if (appointment) {
                        this.appointment = appointment;
                        this.setAppointmentData();
                    }

                    if(appointment?.formId) {
                        this.checkFormExist();
                    } else {
                        this.isFormLoading = false;
                    }
                });
        } else {
            this.setAppointmentData();
        }
    }

    ionViewDidEnter() {
        this.firstField.setFocus();
    }

    ionViewWillLeave(): void {
        unsubscribeAll([
            this.appointmentSubscription,
            this.formResultSubscription
        ]);
    }

    /**
     * marks all dates with appointments in calendar
     */
    setAppointmentData = () => {
        let title = '';
        let description = '';
        if (this.appointment) {
            this.selectedFromDate = formatDateTimeToMoment(this.appointment.dateTimeFrom, true);
            this.selectedToDate = formatDateTimeToMoment(this.appointment.dateTimeTo, true);
            this.selectedFromTime = formatDateTimeToTime(this.appointment.dateTimeFrom, true);
            this.selectedToTime = formatDateTimeToTime(this.appointment.dateTimeTo, true);
            this.selectedReminder = this.appointment.appointmentReminders && this.appointment.appointmentReminders.length > 0
                ? this.notificationReminderConfig.find(
                    reminderOption => reminderOption.id === this.appointment.appointmentReminders[0].minutes_before
                )
                : null;
            this.selectedReminder = this.selectedReminder ? this.selectedReminder : this.notificationReminderConfig[0];

            title = this.appointment.title;
            description = this.appointment.description;
        } else if (!this.selectedFromDate || !this.selectedToDate) {
            this.selectedFromDate = this.selectedDate ? this.selectedDate : formatDateTimeToMoment(today());
            this.selectedToDate = this.selectedDate ? this.selectedDate : formatDateTimeToMoment(today());
        }

        this.appointmentFormGroup = this.formBuilder.group({
            title: [title, [InputValidationService.noWhitespaceValidator, ...AppointmentTitleValidator()]],
            description: [description, [InputValidationService.noWhitespaceValidator, ...AppointmentDescriptionValidator()]],
        });

        if (this.appointment && this.appointment.lockedBySanacorp) {
            this.appointmentFormGroup.controls['title'].disable();

        }

        this.dataLoaded = true;
    };

    /**
     * updates date an times
     *
     * @param event - event from date and time picker
     */
    updateDatesAndTimes = (event: {
        fromDate: Moment,
        fromTime: string,
        toDate: Moment,
        toTime: string,
        dateRangeId: DateRangeOptionCodes}
        ) => {
        this.selectedFromDate = event.fromDate;
        this.selectedToDate = event.toDate;
        this.selectedFromTime = event.fromTime;
        this.selectedToTime = event.toTime;
    };

    onReminderFilterChange = (event) => {
        this.selectedReminder = this.notificationReminderConfig.find(reminderOption => reminderOption.id === event.detail.value);
    };

    /**
     * User wants to delete the appointment
     */
    async onAppointmentDeleteClick() {
        await this.appointmentService.deleteAppointment(this.appointment, true);
    }

    async onFormSubmitted(event: boolean) {
        if(event){
            this.isAlreadySubmitted = true;
            await this.modalService.dismiss();
            await this.modalService.dismiss();
        }
    }

    async onAppointmentFormModal(){
        this.openModal = true;
    }

    async onAppointmentFormModalClose(){
        this.openModal = false;
    }

    checkFormExist(){
        unsubscribe(this.formResultSubscription);
        this.formResultSubscription = this.formQueries.getFormResult(Number(this.appointment?.formId))
            .subscribe(formResult => {
                this.isAlreadySubmitted = !!(formResult && formResult[0]?.id);
                this.isFormLoading = false;
            });
    }

    /**
     * Saves the appointment
     *
     * @param values - form values
     */
    async saveAppointment(values) {
        if (values.lockedBySanacorp) {
            await this.toastService.presentError('Gesperrte Termine dürfen nicht geändert werden.');
            return;
        }
        if (!this.lockedWhileSaving) {
            const startDate = formatMomentDateToDefaultDateTimeFormat(this.selectedFromDate, this.selectedFromTime + ':00');
            this.lockedWhileSaving = true;
            const appointmentReminders = this.selectedReminder !== null && this.selectedReminder.id
                ? {
                    minutes_before: this.selectedReminder.id,
                    reminder: startOfWithUTC('minute', false, startDate, this.selectedReminder.id, true)
                }
                : null;
            if (
                appointmentReminders &&
                compareDates(appointmentReminders.reminder, convertFromLocalTimeToUTC(now(), true), CompareDateEnum.isSameOrBefore)
            ) {
                await this.toastService.presentError('Es kann keine Erinnerung, die in der Vergangenheit liegt, angelegt werden.');
                this.lockedWhileSaving = false;
                return;
            }
            setTimeout(() => {
                const appointmentData = {
                    title: values.title,
                    description: values.description,
                    branch: '',
                    location: '',
                    dateTimeFrom: startDate,
                    dateTimeTo: formatMomentDateToDefaultDateTimeFormat(this.selectedToDate, this.selectedToTime + ':00')
                };

                if (this.appointmentId) {
                    this.appointmentMutations.updateAppointment(
                        this.appointmentId,
                        appointmentData,
                        appointmentReminders ? {...appointmentReminders, ...{appointmentId: this.appointmentId}} : null);
                } else {
                    if (appointmentReminders) {
                        this.appointmentMutations.createAppointment({
                            ...appointmentData,
                            ...{appointmentReminders: {data: [appointmentReminders]}}
                            });
                    } else {
                        this.appointmentMutations.createAppointment(appointmentData);
                    }
                }
            }, 250);
            await this.modalService.dismiss();
        }
    }

    /**
     * track by
     *
     * @param index : number
     * @param item : any
     */
    trackBy(index, item) {
        return item.id;
    }
}
