import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { OrdersMutations } from '../store/graphql/mutations/orders.graphql';
import { ToastService } from './toast.service';
import { FieldLengths } from '../config/input-validation.config';
import { OrderAuditLogInterface, OrderDetailInterface } from '../interfaces/order.interface';
import { addDaysToDate, compareDates, formatDateTimeToDate, today } from '../formatting/date.formatting';
import {
    AuditLogEnum,
    CompareDateEnum,
    FieldLengthEnum,
    KeyCodesEnum,
    matomoIdsEnum,
    OrderStatus,
    OrderType,
    TooltipColorSchemeEnum
} from '../core.enums';

export const maxExtensionDays = 180;

@Injectable({
    providedIn: 'root',
})
export class OrderService {

    constructor(
        private alertController: AlertController,
        public toastService: ToastService,
        private ordersMutations: OrdersMutations
    ) { }

    /**
     * replaces null input with 'k. A.'
     */
    static formatInput(input) {
        if (typeof input !== 'string') {
            input = input.toString();
        }
        return input === '' ? 'k. A.' : input;
    }

    async presentCancelAlert(order: OrderDetailInterface) {
        const lastExtensionAuditLog = order.extendRequest && this.getLastExtensionRequest(order);
        if (this.isCancellationEnabled(order)) {
            let orderCancellationAlertMessage = 'Möchten Sie die Bestellung wirklich stornieren?';
            let orderExtensionAlertMessageAdditional = '';

            if (lastExtensionAuditLog && compareDates(today(),lastExtensionAuditLog.created_at,  CompareDateEnum.diffInDays) <= 7) {
                orderExtensionAlertMessageAdditional += (
                    'Es wurde bereits eine Verlängerung am {{lastExtensionAuditLog}} beantragt.<br/><br/>'
                ).replace('{{lastExtensionAuditLog}}', formatDateTimeToDate(lastExtensionAuditLog.created_at));
            }
            if (order.cancellationRequest) {
                const cancellationEntry = this.getLastCancellationRequest(order);
                if (cancellationEntry) {
                    orderCancellationAlertMessage = (
                        'Es wurde bereits eine Stornierung am {{cancellationDate}} beantragt.<br/>' +
                        'Möchten Sie den Antrag erneut absenden?'
                    ).replace('{{cancellationDate}}', formatDateTimeToDate(cancellationEntry.created_at));
                }
            }
            if (this.isAcknowledgeEnabled(order)) {
                // show additional alert box on disposition delegated orders, since users normally cancel the order instead of accept it
                await this.orderReceivedHelper(order, orderExtensionAlertMessageAdditional, orderCancellationAlertMessage);
            } else {
                await this.cancelAlertHelper(order, orderExtensionAlertMessageAdditional, orderCancellationAlertMessage);
            }

        }
    }

    private async cancelAlertHelper(order: OrderDetailInterface,
                                    orderExtensionAlertMessageAdditional: string = '',
                                    orderCancellationAlertMessage: string = '') {

        const confirm = await this.alertController.create({
            header: 'Stornierung Bestellung ' + order.orderIdOrg,
            cssClass: 'medium',
            inputs: [
                {
                    name: 'reason',
                    type: 'text',
                    placeholder: 'Stornierungsgrund',
                    attributes: {
                        maxlength: FieldLengths[FieldLengthEnum.cancelOrder]
                    }
                }
            ],
            message: orderExtensionAlertMessageAdditional + orderCancellationAlertMessage,
            buttons: [
                {
                    text: 'Abbrechen',
                    handler: () => {
                    }
                },
                {
                    text: 'OK',
                    cssClass: matomoIdsEnum.orderCancelCssClass,
                    handler: data => {
                        const trimmedReason = data.reason.trim();
                        if (trimmedReason.length > 1 && trimmedReason.length <= FieldLengths[FieldLengthEnum.cancelOrder]) {
                            this.ordersMutations.cancelOrder(order.id, trimmedReason);
                            return true;
                        }
                        this.toastService.presentWarning(
                            trimmedReason.length <= 1 ?
                                'Bitte tragen Sie einen Stornierungsgrund ein.' :
                                'Maximale Nachrichtenlänge von ' + FieldLengths[FieldLengthEnum.cancelOrder] + ' Zeichen überschritten: ' +
                                trimmedReason.length + ' Zeichen. Ein Versenden ist bei >' + FieldLengths[FieldLengthEnum.cancelOrder] +
                                ' Zeichen nicht möglich'
                        );
                        return false;
                    }
                }
            ]
        });

        confirm.addEventListener('keyup', async (event) => {
            if(event.key === KeyCodesEnum.ENTER && event.currentTarget instanceof HTMLElement) {
                event.currentTarget.getElementsByTagName('button')[1].click();
            }

            if(event.key === KeyCodesEnum.ESCAPE) {
                void confirm.dismiss();
            }
        });
        confirm.present().then(t => {
            (confirm.getElementsByTagName('input')[0] as HTMLElement).focus();
        });
    }

    private async orderReceivedHelper(order: OrderDetailInterface,
                                            orderExtensionAlertMessageAdditional: string = '',
                                            orderCancellationAlertMessage: string = '') {
        const acceptOrCancelAlert = await this.alertController.create({
            header: 'Ware angekommen?',
            cssClass: 'medium',
            message: 'Ist der Auftrag erledigt und haben Sie die Ware bereits erhalten?',
            buttons: [
                {
                    text: 'Ja',
                    handler: () => {
                        // order should be accepted instead of cancelled
                        this.ordersMutations.dispoAcknowledge(order.id);
                        return true;
                    }
                },
                {
                    text: 'Nein',
                    handler: () => {
                        // proceed to cancel confirmation alert
                        this.cancelAlertHelper(order, orderExtensionAlertMessageAdditional, orderCancellationAlertMessage);
                        return true;
                    }
                }
            ]
        });


        acceptOrCancelAlert.addEventListener('keyup', async (event) => {
            if(event.key === KeyCodesEnum.ENTER) {
                this.ordersMutations.dispoAcknowledge(order.id);
            }

            if(event.key === KeyCodesEnum.ESCAPE) {
                this.cancelAlertHelper(order, orderExtensionAlertMessageAdditional, orderCancellationAlertMessage);
            }
        });

        await acceptOrCancelAlert.present();
    }

    getLastCancellationRequest = (order: OrderDetailInterface): OrderAuditLogInterface => {
        return this.getLastAuditLog(order, AuditLogEnum.ORDER_CANCELLATION_REQUESTED);
    };

    getLastExtensionRequest = (order: OrderDetailInterface): OrderAuditLogInterface => {
        return this.getLastAuditLog(
            order,
            AuditLogEnum.ORDER_EXPIRE_EXTEND_REQUESTED
        );
    };

    getLastReorderRequest = (order: OrderDetailInterface): OrderAuditLogInterface => {
        return this.getLastAuditLog(
            order,
            AuditLogEnum.REORDER_REQUESTED
        );
    };

    getLastAuditLog = (order: OrderDetailInterface, auditLogText): OrderAuditLogInterface => {
        if (order.cancellationRequest || order.extendRequest) {
            const cancellationArray = order.orderAuditLogs
                .filter(auditLog => auditLog.text === auditLogText)
                .sort((a, b) => a.created_at < b.created_at ? 1 : a.created_at > b.created_at ? -1 : 0);
            return cancellationArray.length > 0 ? cancellationArray[0] : null;
        }
        return null;
    };

    /**
     * opens alert for order extension
     *
     * @param order - order to extend
     */
    async presentExtensionAlert(order: OrderDetailInterface) {
        if (this.isExtensionEnabled(order)) {
            const inputFields = [];
            const lastCancellationAuditLog = order.cancellationRequest && this.getLastCancellationRequest(order);
            const lastExtensionAuditLog = order.extendRequest && this.getLastExtensionRequest(order);
            let orderExtensionAlertMessageAdditional = '';
            for (let i = 1; i <= 4; i++) {
                inputFields.push({
                    type: 'radio',
                    value: i,
                    label: i +' Wochen'
                });
            }
            if (lastCancellationAuditLog && compareDates(today(),lastCancellationAuditLog.created_at,  CompareDateEnum.diffInDays) <= 7) {
                orderExtensionAlertMessageAdditional += (
                    'Es wurde bereits eine Stornierung am {{lastCancellationDate}} beantragt.<br/><br/>'
                ).replace('{{lastCancellationDate}}', formatDateTimeToDate(lastCancellationAuditLog.created_at));
            }
            if (order.extendRequest) {
                const auditLogExpiryPayload = lastExtensionAuditLog && lastExtensionAuditLog.payload ? lastExtensionAuditLog.payload : null;
                const auditLogExpiryDate = auditLogExpiryPayload ? JSON.parse(lastExtensionAuditLog.payload).expiryDate : null;
                if (
                    !order.expiryDate || (
                    auditLogExpiryDate &&
                    compareDates(order.expiryDate, auditLogExpiryDate, CompareDateEnum.isBefore)
                    )) {
                    orderExtensionAlertMessageAdditional += (
                        'Es wurde bereits eine Verlängerung bis zum {{extensionDate}} beantragt.<br/><br/>'
                    ).replace('{{extensionDate}}', formatDateTimeToDate(auditLogExpiryDate));
                }
            }

            const confirm = await this.alertController.create({
                header: 'Verlängerung beantragen für Auftrag ' + order.orderIdOrg,
                message: `${orderExtensionAlertMessageAdditional} Bitte wählen Sie den Verlängerungszeitraum aus (nur ${maxExtensionDays} Tage verlängerbar):`,
                inputs: inputFields,
                buttons: [
                    {
                        text: 'Abbrechen',
                        handler: () => {
                        }
                    },
                    {

                        id:'submit',
                        text: 'OK',
                        cssClass: matomoIdsEnum.orderExtensionCssClass,
                        handler: data => {
                            if (!data) {
                                this.toastService.presentWarning('Bitte wählen Sie einen Verlängerungszeitraum aus.');
                                return false;
                            }
                            if (!this.isExtensionWithinDateRange(addDaysToDate(order.expiryDate, data * 7))) {
                                this.toastService.presentWarning(`Der Verlängerungszeitraum darf maximal ${maxExtensionDays} Tage betragen.`);
                                return false;
                            }
                            this.ordersMutations.additionalExtend(
                                order.id,
                                data
                            );
                            return true;
                        }
                    }
                ]
            });


            confirm.addEventListener('keyup', async (event) => {
                if(event.key === KeyCodesEnum.ENTER && event.currentTarget instanceof HTMLElement) {
                    (event.currentTarget.querySelector('#submit') as HTMLElement).click();
                }

                if(event.key === KeyCodesEnum.ESCAPE) {
                    void confirm.dismiss();
                }
            });
            await confirm.present();
        }
    }

    /**
     * opens alert for order acknowledgment
     *
     * @param order - order to acknowledge
     */
    async presentAcknowledgeAlert(order: OrderDetailInterface) {
        if (this.isAcknowledgeEnabled(order)) {
            const confirm = await this.alertController.create({
                header: 'Bestätigung, dass die folgende Bestellung angekommen ist: ' + order.orderIdOrg,
                message: 'Hiermit können Sie bestätigen, dass die Ware fehlerfrei angekommen ist.',
                buttons: [
                    {
                        text: 'Abbrechen',
                        handler: () => {
                        }
                    },
                    {
                        text: 'OK',
                        cssClass: matomoIdsEnum.orderAcceptCssClass,
                        handler: () => {
                            this.ordersMutations.dispoAcknowledge(order.id);
                            return true;
                        }
                    }
                ]
            });

            confirm.addEventListener('keyup', async (event) => {
                if(event.key === KeyCodesEnum.ENTER && event.currentTarget instanceof HTMLElement) {
                    event.currentTarget.getElementsByTagName('button')[1].click();
                }

                if(event.key === KeyCodesEnum.ESCAPE) {
                    void confirm.dismiss();
                }
            });
            await confirm.present();
        }
    }

    isCopyEnabled = (order: OrderDetailInterface) => {
        // Has to be disposition order
        return order && order.type && order.type.toUpperCase() === OrderType.DISPOSITION.toUpperCase();
    };

    copyButtonTooltip = (order: OrderDetailInterface): string|null => {
        // Has to be disposition order
        if (!this.isCopyEnabled(order)) {
            return 'order_copy_locked';
        } else {
            return 'order_copy_button';
        }
    };

    isCancellationEnabled = (order: OrderDetailInterface) => {
        // Only when not ENROUTE, COMPLETED or CANCELLED
        return order.status.toUpperCase() !== OrderStatus.ENROUTE.toUpperCase()
            && order.status.toUpperCase() !== OrderStatus.COMPLETED.toUpperCase()
            && order.status.toUpperCase() !== OrderStatus.CANCELLED.toUpperCase()
            && order.type !== OrderType.DISPOSITION
            && order.type !== OrderType.UE
            && order.type !== OrderType.N_PLUS;
    };

    cancellationButtonTooltip = (order: OrderDetailInterface): string|null => {
        // Only when not ENROUTE, COMPLETED or CANCELLED
        if (order.status.toUpperCase() === OrderStatus.ENROUTE.toUpperCase()
            || order.status.toUpperCase() === OrderStatus.COMPLETED.toUpperCase()
            || order.status.toUpperCase() === OrderStatus.CANCELLED.toUpperCase()) {
            return 'order_cancellation_locked';
        } else if (order.type === OrderType.DISPOSITION) {
            return 'order_cancellation_dispo';
        } else if (order.type === OrderType.UE || order.type === OrderType.N_PLUS) {
            return 'order_cancellation_producer';
        } else {
            return 'order_cancellation_button';
        }
    };

    /**
     * Determine the color scheme of the tooltip for a given order.
     * @param order OrderDetailInterface
     */
    getTooltipColorScheme(order: OrderDetailInterface): TooltipColorSchemeEnum {
        if ((order.type === OrderType.UE || order.type === OrderType.N_PLUS)
            && (order.status.toUpperCase() !== OrderStatus.ENROUTE.toUpperCase()
            && order.status.toUpperCase() !== OrderStatus.COMPLETED.toUpperCase()
            && order.status.toUpperCase() !== OrderStatus.CANCELLED.toUpperCase())) {
            return TooltipColorSchemeEnum.RED;
        }
        return TooltipColorSchemeEnum.DEFAULT;
    }

    isAcknowledgeEnabled = (order: OrderDetailInterface) => {
        // order must be in status delegated
        return order && order.type && order.type === OrderType.DISPOSITION
            && order.status && order.status.toUpperCase() === OrderStatus.DELEGATED.toUpperCase();
    };

    acknowledgeButtonTooltip = (order: OrderDetailInterface): string|null => {
        // order must be in status delegated
        if (!this.isAcknowledgeEnabled(order)) {
            return  'order_acknowledge_locked';
        } else {
            return 'order_acknowledge_button';
        }
    };

    isExtensionEnabled = (order: OrderDetailInterface) => {
        // order must be in status pending
        // order must be additional order
        return this.isExtensionOrderValid(order)
            && this.isExtensionWithinDateRange(order.expiryDate);
    };

    private isExtensionOrderValid(order: OrderDetailInterface) {
        return order.type.toUpperCase() === OrderType.ADDITIONAL.toUpperCase()
            && order.status.toUpperCase() !== OrderStatus.CANCELLED.toUpperCase()
            && order.status.toUpperCase() !== OrderStatus.COMPLETED.toUpperCase()
            && order.expiryDate >= today();
    }

    isExtensionWithinDateRange(expiryDate: string) {
        return  compareDates(expiryDate, today() , CompareDateEnum.diffInDays) <= maxExtensionDays;
    }

    extensionButtonTooltip = (order: OrderDetailInterface): string|null => {
        if (!this.isExtensionOrderValid(order)) {
            return 'order_extension_locked';
        }
        if (!this.isExtensionWithinDateRange(order.expiryDate)) {
            return 'order_extension_date_range_locked';
        }
        return 'order_extension_button';
    };
}
