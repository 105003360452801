import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { CoreModule } from '../../../core/core.module';
import { StatisticViewComponent } from './statistic-view/statistic-view.component';
import { StatisticsComponentsModule } from '../components/statistics.components.module';
import { StatisticsBaseComponent } from './statistics-base/statistics-base.component';
import { StatisticsCoopBonusComponent } from './statistics-coop-bonus/statistics-coop-bonus.component';
import { StatisticsMonthlyTargetSalesComponent } from './statistics-monthly-target-sales/statistics-monthly-target-sales.component';
import { StatisticsOrdersOpenComponent } from './statistics-orders-open/statistics-orders-open.component';
import { StatisticsReturnsComponent } from './statistics-returns/statistics-returns.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        IonicModule,
        RouterModule,
        StatisticsComponentsModule,
    ],
    declarations: [
        StatisticViewComponent,
        StatisticsBaseComponent,
        StatisticsCoopBonusComponent,
        StatisticsMonthlyTargetSalesComponent,
        StatisticsOrdersOpenComponent,
        StatisticsReturnsComponent
    ],
    exports: [
        StatisticsCoopBonusComponent,
        StatisticsMonthlyTargetSalesComponent,
        StatisticsOrdersOpenComponent,
        StatisticsReturnsComponent,
    ]
})
export class StatisticsWidgetsModule {
}
