import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { GridsterModule } from 'angular-gridster2';
import { CustomizableGridComponent } from './customizable-grid.component';
import { CustomizableGridPopoverComponent } from './components/customizable-grid-popover/customizable-grid-popover.component';

import { CoreModule } from '../../../core/core.module';
import { OrdersWidgetsModule } from '../../../pages/logistics/pages/orders/widgets/orders.widgets.module';
import { ReturnsWidgetsModule } from '../../../pages/logistics/pages/returns/widgets/returns.widgets.module';
import { StatisticsWidgetsModule } from '../../../pages/statistics/widgets/statistics.widgets.module';
import { CalendarWidgetsModule } from '../../../pages/calendar/widgets/calendar.widgets.module';
import { OffersWidgetsModule } from '../../../pages/offers/widgets/offers.widgets.module';
import { NewsWidgetsModule } from '../../../pages/news/widgets/news.widgets.module';
import { StatisticsComponentsModule } from '../../../pages/statistics/components/statistics.components.module';

import { NewsSliderWidget } from '../widgets/news-slider-widget/news-slider-widget.component';
import { NoteWidgetComponent } from '../widgets/note-widget/note-widget.component';
import { UpdatesWidgetComponent } from '../widgets/updates-widget/updates-widget.component';
import { StatisticsWidgetComponent } from '../widgets/statistics-widget/statistics-widget.component';

import { IsEditablePipe } from '../../pipes/isEditable.pipe';
import { WidgetOptionPipe, WidgetOptionsPipe, WidgetOptionValuePipe } from '../../pipes/widgetOptions.pipe';
import { EditWidgetComponent } from './components/edit-widget/edit-widget.component';
import { TablesWidgetComponent } from '../widgets/tables-widget/tables-widget.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        ReactiveFormsModule,
        GridsterModule,
        CoreModule,

        OrdersWidgetsModule,
        ReturnsWidgetsModule,
        StatisticsWidgetsModule,
        CalendarWidgetsModule,
        OffersWidgetsModule,
        NewsWidgetsModule,
        FormsModule,
        StatisticsComponentsModule,
        StatisticsWidgetsModule
    ],
    declarations: [
        CustomizableGridComponent,
        CustomizableGridPopoverComponent,
        EditWidgetComponent,

        IsEditablePipe,
        WidgetOptionPipe,
        WidgetOptionsPipe,
        WidgetOptionValuePipe,

        NewsSliderWidget,
        NoteWidgetComponent,
        StatisticsWidgetComponent,
        TablesWidgetComponent,
        UpdatesWidgetComponent,
    ],

    exports: [
        CustomizableGridComponent
    ]
})
export class CustomizableGridModule {
}
